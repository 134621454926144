import { formLabelClasses } from '@mui/material';
import axios from 'axios'
import store from '../store/Store.js'
import getWeek from 'date-fns/getWeek';
import { format, getMonth, addDays } from 'date-fns';

//the results API is dependent on the Akumen model,study and view
// for the moment this is fixed here
// but will ultimatly be settings under the user login
const MODEL_NAME = "MPN_Model";
const STUDY_NAME = "Stability";
const VIEW_NAME = "history_vw";
const FORECAST_VIEW = "forecast_vw"
const DAILYACTIVITY = "daily_agg_vw"

//API V2 database tables -- Obsolete activity data is being moved to a view
const ACTIVITY_NOTES_TABLE = "mpn_activity_notes_v2";

//Request daily data from the Akumen model
//  OnDataLoaded(dataPArams,data[]) is called after data is recieved

/**
 * Get the start date for the forecast period based on
 * the last available historical record
 */
function getForecastStartDate(scenarioname, OnDataLoaded) {
    let state = store.getState();
    
    const dataURL = `${state.common.baseUrl}/api/v1/models/${MODEL_NAME}/${STUDY_NAME}/${VIEW_NAME}/results`;

    const requestConfig = {
        query: `agg = 'avg' AND mine_area = 'all' AND scenarioname = '${scenarioname}'`,
        sorts: [
            {
                'column_name': 'date',
                'direction': 'desc'
            }
        ],
        fields: [
            'date'
        ],
        page_size: 1,
        page: 1,
    }

    axios.post(dataURL, requestConfig, {headers:{Authorization:`Bearer ${state.security.jwt}`}})
        .then(response => {
            const lastHistoricalRecord = response.data.DataValues[0];

            const forecastDate = addDays(new Date(lastHistoricalRecord.date).getTime(), 1);

            if (OnDataLoaded) {
                OnDataLoaded(forecastDate);
            }
        })
        .catch(error => {
            console.log(error);
        });
}

function getDailyData(scenarionname,OnDataLoaded) {

    let state = store.getState();
    
    const dataURL = 
        `${state.common.baseUrl}/api/v1/models/${MODEL_NAME}/${STUDY_NAME}/${VIEW_NAME}/results`;

    const requestConfig = {
        query: `agg = 'avg' AND mine_area = 'all' AND scenarioname = '${scenarionname}'`,
        sorts: [
            {
              "column_name": "date",
              "direction": "asc"
            }
          ],
        fields: [
          "value","date","scenarioname","agg"
        ]
    }

    axios.post(dataURL, requestConfig, {headers:{Authorization:`Bearer ${state.security.jwt}`}})
        .then( response => {
            const newData = [];
            var prevVal = 0;
            //console.log('Get daily data ', response.data);
            response.data.DataValues.map((rec) => {
                if ( prevVal == 0 ) prevVal = Number(rec.value);
                newData.push({
                    date: new Date(rec.date).getTime(),
                    value: Number(rec.value),
                    open: prevVal
                })
                prevVal = Number(rec.value);
            })
            if ( OnDataLoaded ) {
                OnDataLoaded(newData);
            }
        })
        .catch(error => {
            console.log(error);
        })
}

///////////////
// Forecast data
///////////////
function getForecastData(scenarionname,OnDataLoaded) {

  let state = store.getState();
  
  const dataURL = 
      `${state.common.baseUrl}/api/v1/models/${MODEL_NAME}/${STUDY_NAME}/${FORECAST_VIEW}/results`;

  const requestConfig = {
      query: `agg = 'avg' AND mine_area = 'all' AND scenarioname = '${scenarionname}'`,
      sorts: [
          {
            "column_name": "date",
            "direction": "asc"
          }
        ],
      fields: [
        "date","scenarioname","agg","trend","yhat","yhat_lower","yhat_upper"
      ]
  }

  axios.post(dataURL, requestConfig, {headers:{Authorization:`Bearer ${state.security.jwt}`}})
      .then( response => {
          var newData = [];
          //console.log('Get daily data ', response.data);
          newData = response.data.DataValues.map((rec) => {
              var newRec = 
              {
                  date: new Date(rec.date).getTime(),
                  value: Number(rec.yhat),
                  lower: Number(rec.yhat_lower),
                  upper: Number(rec.yhat_upper)
              }
              
              return newRec
          })
          if ( OnDataLoaded ) {
              OnDataLoaded(newData);
          }
      })
      .catch(error => {
          console.log(error);
      })
}

function getActivityNotes(activityName, dataLoadedCallback) {
    let state = store.getState();
    
    const dataURL = 
        `${state.common.baseUrl}/api/v2/database/${ACTIVITY_NOTES_TABLE}/json`;

    const requestConfig = {
        query: `activity = '${activityName}'`,
        fields: [
            "date",
            "activity",
            "categories",
            "shift",
            "comment"
        ],
        sorts: [
            {
            "column_name": "date",
            "direction": "asc"
            }
        ]
    }
    
    axios.post(dataURL, requestConfig, {headers:{Authorization:`Bearer ${state.security.jwt}`}})
        .then( response => {
            if ( dataLoadedCallback ) {
                let resData = response.data.map(r => {
                    let rec = r;
                    rec.date = new Date(r.date).getTime();
                    rec.categories = JSON.parse(r.categories);
                    return rec;
                });
                dataLoadedCallback(resData);
            }
        })
        .catch(error => {
            console.log(error);
            if( error.response ) {
                console.log(error.response.data);
                //If table doesn't exist create it
                if ( error.response.data['error'].includes('does not exist')) {
                  createActivityNotesTable();  
                } 
            }
        }
    )
}

function SaveActivityNote(note,resultCallback) {
  let state = store.getState();
    
    const dataURL = 
        `${state.common.baseUrl}/api/v2/database/${ACTIVITY_NOTES_TABLE}/json`; 
    
    let newRec = [{
      activity: note.activity,
      date: format(new Date(note.date),'MM/dd/yyyy'),
      categories: JSON.stringify(note.categories),
      comment: note.comment,
      shift: note.shift
    }];

    axios.put(dataURL, newRec, {headers:{Authorization:`Bearer ${state.security.jwt}`}})
      .then( response => {      
            console.log('Note Saved' ,response);
      })
      .catch(error => {
          console.log(error.response);
      })
}

function UpdateActivityNote(note,resultCallback) {
  let state = store.getState();
    
    const dataURL = 
        `${state.common.baseUrl}/api/v2/database/${ACTIVITY_NOTES_TABLE}/updaterows`; 
    
    let updateRec = {
      data: [{
        activity: note.activity,
        date: format(new Date(note.date),'MM/dd/yyyy'),
        categories: JSON.stringify(note.categories),
        comment: note.comment,
        shift: note.shift
      }],
      key_columns: ["date", "activity"]
  };

    axios.put(dataURL, updateRec, {headers:{Authorization:`Bearer ${state.security.jwt}`}})
      .then( response => {      
            console.log('Note Updated' ,response);  // TODO NEED TO CHECK IF UPDATE IS NOT 0 AS 0 MEANS NOTHING UPDATED
      })
      .catch(error => {
          console.log(error.response);
      })
}

// function UpdateOrCreateActivityNote(note) {
function UpdateOrCreateActivityNote(note, dataLoadedCallback) {
  console.log('UpdateOrCreateActivityNote() has been called');
  let state = store.getState();
  
  const dataURL = `${state.common.baseUrl}/api/v2/database/${ACTIVITY_NOTES_TABLE}/json`;
  
  var date_str = format(new Date(note.date),'MM/dd/yyyy')
  console.log(date_str)
  const requestConfig = {
      // query: `activity = '${note.activity}' and date = '${format(new Date(note.date),'MM/dd/yyyy')}`,
      query: "activity = '"+note.activity+"' and date = '"+date_str+"'",
      fields: [
          "date",
          "activity",
          "categories",
          "shift",
          "comment"
      ]//,
      // sorts: [
      //     {
      //     "column_name": "date",
      //     "direction": "asc"
      //     }
      // ]
  }
  
  axios.post(dataURL, requestConfig, {headers:{Authorization:`Bearer ${state.security.jwt}`}})
      .then( response => {
          console.log('Response in UpdateOrCreateActivityNote()')

          console.log(response.data)

          if (response.data.length > 0) {
            console.log('There is a note already here')
            UpdateActivityNote(note)
          } else {
            console.log('NO NOTES HERE')
            SaveActivityNote(note)
          }


          // if ( dataLoadedCallback ) {
              // console.log(response.data)
              
              // dataLoadedCallback(response.data);
          // }
      })
      .catch(error => {
          console.log(error);
          if(error.response ) {
             console.log(error)
             console.log(error.response.data);
             // //If table doesn't exist create it
             // if ( error.response.data['error'].includes('does not exist')) {
             //   createActivityNotesTable();  
             // } 
          }
      }
  )
}
function createActivityNotesTable() {
  let state = store.getState();
  
  const dataURL = 
      `${state.common.baseUrl}/api/v2/database/${ACTIVITY_NOTES_TABLE}`;

  const tableSchema = {
      "columns": [
        {
          "name": "date",
          "data_type": "datetime",
          "nullable": false,
          "primary_key": true
        },
        {
          "name": "activity",
          "data_type": "string",
          "nullable": false,
          "primary_key": true,
        },
        {
          "name": "categories",
          "data_type": "string",
          "nullable": false
        },
        {
          "name": "shift",
          "data_type": "string",
          "nullable": true
        },
        {
          "name": "comment",
          "data_type": "string",
          "nullable": true
        }
      ]
  }

  axios.put(dataURL, tableSchema, {headers:{Authorization:`Bearer ${state.security.jwt}`}})
    .then( response => {
        //Table created
        console.log('Create activity notes table' ,response);
    })
    .catch(error => {
        console.log(error);
    })

}

function getDailyActivityDetails(scenarionname) {

  let state = store.getState();
  
  const dataURL = 
      `${state.common.baseUrl}/api/v1/models/${MODEL_NAME}/${STUDY_NAME}/${DAILYACTIVITY}/results`;

  const requestConfig = {
      query: `\"Shift\" = 'Combined' AND scenarioname = '${scenarionname}'`,
      sorts: [
          {
            "column_name": "RecDate",
            "direction": "asc"
          }
        ],
      fields: [
        "RecDate","Crew","Machine Count","Actual","Target","scenarioname","changepoint_count"
      ]
  }

  return axios.post(dataURL, requestConfig, {headers:{Authorization:`Bearer ${state.security.jwt}`}});
}

function getAggActivityDetails(scenarionname,agg,OnDataLoaded) {
  //get aggrigated activity details
  //  default agg = 'Weekly'
  let aggView = "weekly_agg_vw";
  if (agg == 'Monthly') aggView = "monthly_agg_vw";
  if (agg == 'Quarterly') aggView = "quarterly_agg_vw";

  let state = store.getState();
  
  const dataURL = 
      `${state.common.baseUrl}/api/v1/models/${MODEL_NAME}/${STUDY_NAME}/${aggView}/results`;

  const requestConfig = {
      query: `\"Shift\" = 'Combined' AND scenarioname = '${scenarionname}'`,
      sorts: [
          {
            "column_name": "RecDate",
            "direction": "asc"
          }
        ],
      fields: [
        "RecDate","Crew","Machine Count","Actual","Target","scenarioname","changepoint_count"
      ]
  }

  axios.post(dataURL, requestConfig, {headers:{Authorization:`Bearer ${state.security.jwt}`}})
      .then( response => {
        let data = response.data.DataValues.map(rec => {
          let data = {
              date: new Date(rec.RecDate).getTime(),
              actual: parseFloat(rec.Actual),
              activity: rec.scenarioname,
              crew : parseFloat(rec.Crew),
              machinery_count: parseFloat(rec['Machine Count']),
              machinery_type: '',
              prod_hours: 8,
              target: parseFloat(rec.Target),
              hasInflection: (rec.changepoint_count > 0)
          }

          data.offTarget = data.actual < data.target;
          return data;
        });

          
        if ( OnDataLoaded ) {
            OnDataLoaded(data);
        }
      })
      .catch(error => {
          console.log(error);
      })
}

function getMonthlyActivityDetails_test(scenarionname,OnDataLoaded) {

  let state = store.getState();
  
  const dataURL = 
      `${state.common.baseUrl}/api/v1/models/${MODEL_NAME}/${STUDY_NAME}/${VIEW_NAME}/results`;

  const requestConfig = {
      query: `agg = 'sum' AND mine_area = 'all' AND scenarioname = '${scenarionname}'`,
      sorts: [
          {
            "column_name": "date",
            "direction": "asc"
          }
        ],
      fields: [
        "value","date","scenarioname","agg"
      ]
  }

  axios.post(dataURL, requestConfig, {headers:{Authorization:`Bearer ${state.security.jwt}`}})
      .then( response => {
          var testData = [];
          var monthStart = new Date(response.data.DataValues[0].date);
          var currentMonth = getMonth(monthStart);
          var month = {
            date: monthStart.getTime(),
            actual: 0,
            activity: response.data.DataValues[0].scenarioname,
            crew : 0,
            machinery_count: 0,
            machinery_type: 'unknown',
            prod_hours: 0,
            target: 0,
            hasInflection: false 
          }
          for(let i=0; i < response.data.DataValues.length; i++) {
            var rec = response.data.DataValues[i];
            var day = new Date(rec.date);
            if ( getMonth(day) !== currentMonth || i === response.data.DataValues.length - 1) {
              //new week
              testData.push(month);

              currentMonth = getMonth(day);
              month = {
                date: day.getTime(),
                actual: parseInt(rec.value),
                activity: rec.scenarioname,
                crew : 1,
                machinery_count: 1,
                machinery_type: 'unknown',
                prod_hours: 8,
                target: 50,
                hasInflection: false 
              }

            }
            else {
              //accumulate 
              month.actual += parseInt(rec.value);
              month.crew += 1;
              month.machinery_count += 1;
              month.prod_hours += 8;
              month.target += 50;
            }
          }
          
          if ( OnDataLoaded ) {
              OnDataLoaded(testData);
          }
      })
      .catch(error => {
          console.log(error);
      })
}

function readCSVFileOLD(file_path) {
  const reader = new FileReader();
  
  var csv_arr = null

  reader.onload = function (event) {
    console.log("readCSVFile has had loaded file")
    console.log(event)
    console.log(event.target.result)

    csv_arr = csvToArray(event.target.result)

  };

  reader.readAsText(file_path);
  console.log("csv_arr HERE!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!")
  console.log(csv_arr)
  return csv_arr
}

async function readCSVFile(file_path) {
  var csv_arr = null
  try {
    const fileContents = await readUploadedFileAsText(file_path)
    console.log("fileContents!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!")
    console.log(fileContents)
    csv_arr = csvToArray(fileContents)

  } catch (e) {
    console.log("e!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!")
    console.log(e)
  }

  console.log("csv_arr HERE!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!")
  console.log(csv_arr)
  return csv_arr
}


function readUploadedFileAsText(inputFile){
  // Source ***************************************************************
  // https://blog.shovonhasan.com/using-promises-with-filereader/
  // **********************************************************************
  // used to convert FileReader to a promise
  const temporaryFileReader = new FileReader();

  return new Promise((resolve, reject) => {
    temporaryFileReader.onerror = () => {
      temporaryFileReader.abort();
      reject(new DOMException("Problem parsing input file."));
    };

    temporaryFileReader.onload = () => {
      resolve(temporaryFileReader.result);
    };
    temporaryFileReader.readAsText(inputFile);
  });
};

function csvToArray(str, delimiter = ",") {
  // Source ***************************************************************
  // https://sebhastian.com/javascript-csv-to-array/
  // **********************************************************************
  // slice from start of text to the first \n index
  // use split to create an array from string by delimiter
  const headers = str.slice(0, str.indexOf("\n")).split(delimiter);

  // slice from \n index + 1 to the end of the text
  // use split to create an array of each csv value row
  const rows = str.slice(str.indexOf("\n") + 1).split("\n");

  // Map the rows
  // split values from each row into an array
  // use headers.reduce to create an object
  // object properties derived from headers:values
  // the object passed as an element of the array
  const arr = rows.map(function (row) {
    const values = row.split(delimiter);
    const el = headers.reduce(function (object, header, index) {
      object[header] = values[index];
      return object;
    }, {});
    return el;
  });

  // return the array
  console.log("arr!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!")
  console.log(arr)
  return arr;
}

function uploadFileToDocManager(file_obj,category) {
   let state = store.getState();
  
  const dataURL = 
      `${state.common.baseUrl}/api/v1/documents/${category}/create?overwriteExisting=true`;
  // const content_type = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' for excel files
  var formData = new FormData()
  formData.append('file', new Blob([file_obj], {type: 'text/csv'}), file_obj.name);
  
  return axios.post(dataURL, formData, {headers:{Authorization:`Bearer ${state.security.jwt}`}});
}

function getDocumentList(category) {
  let state = store.getState();
  //Currently only staging supports this api call
  const dataURL = 
      `${state.common.baseUrl}/api/v1/documents/${category}/details`;
    
  return axios.get(dataURL, {headers:{Authorization:`Bearer ${state.security.jwt}`}})
}

//Get current status of scenarios
function getStudyRunStatus(statusCallback = null) {
  //statusCallback(scenarioStatus)
  let state = store.getState();
  const getURL = `${state.common.baseUrl}/api/v1/models/${MODEL_NAME}/${STUDY_NAME}`;

  axios.get(getURL, {headers:{Authorization:`Bearer ${state.security.jwt}`}})
  .then(response => {
      //console.log(response.data);
      let scenarioStatus = response.data.scenarios.map(scenario => {
        
        let status = {
          name: scenario.name,
          status: scenario.run_status,
          is_executing: scenario.is_executing
        }
        
        return status;
      })
      //console.log(scenarioStatus);
      if (statusCallback) {
          statusCallback(scenarioStatus);
      }
  })
  .catch(error => {
    //handle error
  })

}

//Get input_file parameter value for each of the activitys
function getActiveFilenames(callback = null) {
  //TODO get active filenames
  const activeFiles = {};
  let state = store.getState();
  let urls = [
    `${state.common.baseUrl}/api/v1/models/${MODEL_NAME}/${STUDY_NAME}/Jumbo/input_parameters?parameter_names=input_file`,
    `${state.common.baseUrl}/api/v1/models/${MODEL_NAME}/${STUDY_NAME}/Bogging/input_parameters?parameter_names=input_file`,
    `${state.common.baseUrl}/api/v1/models/${MODEL_NAME}/${STUDY_NAME}/Trucking/input_parameters?parameter_names=input_file`,
    `${state.common.baseUrl}/api/v1/models/${MODEL_NAME}/${STUDY_NAME}/Long Hole Drilling/input_parameters?parameter_names=input_file`,
    `${state.common.baseUrl}/api/v1/models/${MODEL_NAME}/${STUDY_NAME}/Jumbo/input_parameters?parameter_names=plan_file`,
  ];

  //NB - to get plan file we still call for paramater at senario level,
  //  as it is a 'Model' scope paramaeter we can still access it

  Promise.all(urls.map(url => axios.get(url, {headers:{Authorization:`Bearer ${state.security.jwt}`}})))
  .then(([development, bogging, trucking, drilling, plan]) => {
      activeFiles['Development'] = extractFilename(development);
      activeFiles['Bogging'] = extractFilename(bogging);
      activeFiles['Trucking'] = extractFilename(trucking);
      activeFiles['Drilling'] = extractFilename(drilling);
      activeFiles['Plan'] = extractFilename(plan);
      if (callback) {
        console.log('callback - activeFiles()')
        callback(activeFiles)
      }
  })
  .catch(errors => {
    console.log(errors);
  });

  function extractFilename(results) {
    //Extract the filename for result data returned from the 
    //get input_parameters call in getActiveFilenames
    try {
      let data = results.data.map(rec => {
        if ( rec.parameter_name == 'input_file' || rec.parameter_name == 'plan_file' ) {
          return convertXmlToJson(rec.variant_data.Xml);
        }

      });

      return data[0].FileName;
    }
    catch (e) {
      return '';
    }
  }

  function convertXmlToJson(xmlString) {
    const jsonData = {};
    
    for (const result of xmlString.matchAll(/(?:<(\w*)(?:\s[^>]*)*>)((?:(?!<\1).)*)(?:<\/\1>)|<(\w*)(?:\s*)*\/>/gm)) {
        const key = result[1] || result[3];
        const value = result[2] && convertXmlToJson(result[2]); //recusrion
        jsonData[key] = ((value && Object.keys(value).length) ? value : result[2]) || null;
    }
    return jsonData;
  }

}


function setFileInputParam(documentId, file, scenario) {
  //Dounument has to have been upladed to document manager
  
  let state = store.getState();
  
  const inputParamURL = 
      `${state.common.baseUrl}/api/v1/models/${MODEL_NAME}/${STUDY_NAME}/${scenario=='MinePlan' ? 'Trucking' : scenario}/SaveInputParameter`;
  
  var request_body = {
    "input_parameter": {
        "parameter_name": `${scenario=='MinePlan' ? 'plan_file' : 'input_file'}`,
        "parameter_type": "File",
        "expression": "",
        "variant_data": {
              // Contains the ExternalDataID of the CSV in the doc manager
          "Xml": "<ExternalData><ExternalDataId>"+documentId+"</ExternalDataId><FileName>" +file.name+"</FileName></ExternalData>"
        },
        "extension_data": null,
        "scope": `${scenario=='MinePlan' ? 'Model' : 'Scenario'}`,
      },
      "scope": `${scenario=='MinePlan' ? 'Model' : 'Scenario'}`,
      "reset_run_status": true
  };
  console.log("SetPramURL=",inputParamURL,request_body);
  
  return axios.post(inputParamURL, request_body, {headers:{Authorization:`Bearer ${state.security.jwt}`}})
}

function setDefaultInputParams(scenario) {
  //Set default parameters to run the scenario
  let state = store.getState();
  
  const inputParamURL = 
      `${state.common.baseUrl}/api/v1/models/${MODEL_NAME}/${STUDY_NAME}/${scenario}/SaveInputParameters`;
  
  var request_body =  SCENARIO_DEFAULT_PARAMS;
    
  console.log("Default SetPramURL=",inputParamURL,request_body);
  
  return axios.post(inputParamURL, request_body, {headers:{Authorization:`Bearer ${state.security.jwt}`}})
}

function executeScenarios(scenarios) {
  let state = store.getState();
  const inputParamURL = 
      `${state.common.baseUrl}/api/v1/execute/${MODEL_NAME}/${STUDY_NAME}`;
  
  var request_body = {
    "scenario_names": scenarios,
    "clear_outputs": true
  };
    
  console.log("Executing ->",inputParamURL,request_body);
  
  axios.post(inputParamURL, request_body, {headers:{Authorization:`Bearer ${state.security.jwt}`}})
  .then( response => {
    console.log('Executed' ,response);
  })
  .catch(error => {
    console.log('Execution Error:', error);
  })
}

const SCENARIO_DEFAULT_PARAMS = {
  "input_parameters": [
    {
    "parameter_name": "aggregate_period",
    "parameter_type": "String",
    "parameter_type_string": "String",
    "expression": "D",
    "scope": "Scenario"
  },
  {
    "parameter_name": "date_format",
    "parameter_type": "String",
    "parameter_type_string": "String",
    "expression": "%d/%m/%Y",
    "scope": "Scenario"
  },
  {
    "parameter_name": "start_date",
    "parameter_type": "String",
    "parameter_type_string": "String",
    "expression": "1 Jan 2020",
    "scope": "Scenario"
  },
  {
    "parameter_name": "end_date",
    "parameter_type": "String",
    "parameter_type_string": "String",
    "expression": "1 Mar 2021",
    "scope": "Scenario"
  },
  {
    "parameter_name": "date_field_name",
    "parameter_type": "String",
    "parameter_type_string": "String",
    "expression": "RecDate",
    "scope": "Scenario"
  },
  {
    "parameter_name": "roll_up_mine_area",
    "parameter_type": "Enumeration",
    "parameter_type_string": "Enumeration",
    "expression": "1",
    "extension_data": "[{\"id\":0,\"name\":\"No\"},{\"id\":1,\"name\":\"Yes\"}]",
    "scope": "Scenario"
  },
  {
    "parameter_name": "mine_area_field_name",
    "parameter_type": "String",
    "parameter_type_string": "String",
    "expression": "FromMineAreaName",
    "scope": "Scenario"
  },
  {
    "parameter_name": "value_field_name",
    "parameter_type": "String",
    "parameter_type_string": "String",
    "expression": "TKM",
    "scope": "Scenario"
  }
  ]
};

export { 
  getActivityNotes,
  getForecastData,
  getDailyActivityDetails,
  getAggActivityDetails,
  SaveActivityNote,
  getForecastStartDate,
  UpdateOrCreateActivityNote,
  UpdateActivityNote,

  readCSVFile,
  uploadFileToDocManager,
  setFileInputParam,
  setDefaultInputParams,
  executeScenarios,
  getDocumentList,
  getActiveFilenames,

  getStudyRunStatus
};
export default getDailyData;
/*

{
  "query": "agg = \'sum\' AND scenarioname = \'Trucking Day\'",
  "fields": [
    "value","date","scenarioname","agg"
  ],
  "page_size": 100,
  "page": 1 
}

*/