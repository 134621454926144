import PropTypes from 'prop-types';
import { Stack, Box, Typography } from '@mui/material'

const formatValue = (number) => {
    let str = number.toFixed(0);
    if (Number.parseFloat(str) != number )
        str = number.toFixed(1);
    if (number > 100 ) 
        str = number.toFixed(0)
    if (number > 10000 ) {
        str = (number/1000).toFixed(1) + 'k' 
    }        
    return str;
}

export default function DashboardStatistic(props) {
    const {
        targetLabel,
        target,
        comparisonLabel,
        label,
        statistic,
    } = props;

    const targetDisplay = formatValue(target);
    const statisticDisplay = formatValue(statistic);

    const comparison = (() => {
        if (target === 0) {
            return 0;
        }

        let comparison = ((statistic / target) - 1) * 100;

        // round to 2 decimal places
        return Math.round((comparison + Number.EPSILON) * 100) / 100;
    })();

    const comparisonDisplay = new Intl.NumberFormat().format(comparison);

    return (
        <Stack direction="column" spacing={2}>
            
            <Box>
                <Typography variant="overline">{targetLabel}</Typography>
                <Typography fontWeight={700}>{target > 0 ? targetDisplay + " Tonnes" : "-"}</Typography>
            </Box>
                

            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 2, sm: 6 }}>
                <Box>
                    <Typography variant="overline">{label}</Typography>
                    <Typography variant="h4">{statisticDisplay} Tonnes</Typography>
                </Box>

                <Box>
                    <Typography variant="overline">{comparisonLabel}</Typography>

                    <Typography variant="h4" color={ comparison < 0 ? 'error' : 'success.main' }>
                        {comparisonDisplay}%
                    </Typography>
                </Box>
            </Stack>
        </Stack>
    )
}

DashboardStatistic.propTypes = {
    targetLabel: PropTypes.string.isRequired,
    target: PropTypes.number.isRequired,
    comparisonLabel: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    statistic: PropTypes.number.isRequired,
}