import { TableRow, TableCell } from '@mui/material'

export default function SettingsHeadingRow(props) {
    return (
        <TableRow>
            <TableCell component="th" scope="row" colSpan={3} sx={{ fontWeight: 700 }}>
                {props.children}
            </TableCell>
        </TableRow>
    )
}