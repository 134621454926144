import { useEffect, useState } from 'react';
import { Container, Box, Typography, Divider, Button, Input, Snackbar,Stack } from '@mui/material'

import DataUploadsTable from '../components/DataUploadsTable';
import { PageNavButton } from '../components/PageNavButton';
import {readCSVFile, uploadFileToDocManager, setCSVAsInputParam, getActiveFilenames, setFileInputParam, setDefaultInputParams, executeScenarios} from '../components/DataAPI';
import ActivateDataDialog from '../components/ActivateDataDialog';
import ActivityDataSourceTable from '../components/ActivityDataSourceTable';


export default function UploadsView() {
    const [uploadedFile, setUploadedFile] = useState();
    const [uploadingFile, setUploadingFile] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [openActivateData, setOpenActivateData] = useState(false);

    const [isExecuting, setIsExecuting] = useState(false);

    const [activeFiles, setActiveFiles] = useState([]);

    useEffect(() => {
        
        getActiveFilenames(setActiveFiles);    
    },[]);

    const fileHandler = event => {
        console.log(event)
        
        //TODO Multiple files ??
        uploadActivityFile(event.target.files[0],'UIUpload')    
    };

    const uploadActivityFile = (fileObj,activity) => {
        
        setUploadingFile(true);

        //Add selected file to document libary
        uploadFileToDocManager(fileObj,activity)
        .then(response => {
            setUploadedFile(fileObj);
            setOpenSnackbar(true);
            setUploadingFile(false);
            
            console.log('Upload reponse ->',response);

            //Set file as current file
            let documentId = response.data[0];
            setFileInputParam(documentId,fileObj,activity)
            .then(response => {
                console.log('Param Set - ',response);
                
                let newActiveList = activeFiles;
                newActiveList[activity] = fileObj.name;
                setActiveFiles(newActiveList);
            })
            .catch(error =>{
                console.log('INPUT PARAM ERROR' , error);
            });
            
        })
        .catch(error => {
            //Upload error
            console.log(error);
            setUploadingFile(false);
        })
    }

    const setActivityInputParams = (activity) => {
        if( activity !== 'MinePlan ') {
            //set default paramters
            setDefaultInputParams(activity)
            .then( resp => {

            })
            .catch(error => {
                console.log("Error - Default params:",error)
            })
        }
    }

    const executeActivities = () => {
        //TODO - pass activities (scenarios) to execute
        //TODO - monitor execution (? loop, signalR)
        setIsExecuting(true);
        //test
        executeScenarios([
            "Trucking",
            "Bogging",
            "Jumbo",
            "Long Hole Drilling",
            "Trucking - TonnesReconciled"
        ]);
        
        //TODO - query scenarios status
        setTimeout(()=>{
            setIsExecuting(false);    
        },40000)     
    }

    return (
        <Container maxWidth="2xl">
            <PageNavButton pageTitle='Mine Site Data upload' />

            <Box alignItems='center' display='flex' component="span" sx={{ py: 2}}>
                <Box sx={{ flexGrow: 1 }}>
                    <Typography align='left' variant="h6" component="h2" sx={{ textTransform: 'uppercase'}}>
                        Manage Data
                    </Typography>
                </Box>

                <Box sx={{ alignItems: 'right' }}>
                    
                    <Button
                        disableElevation
                        onClick={() => {
                            executeActivities();
                        }}
                        variant="contained" size="large" color="secondary" component="span">
                        {isExecuting ? 'Analysing...' : 'Analyse All'}
                    </Button>
                </Box>
            </Box>

            <Divider sx={{ mb: '40px' }} />

            <ActivityDataSourceTable
                isExecuting={isExecuting}
            />

            <Divider sx={{ mb: '40px' }} />         

            <Snackbar
                open={openSnackbar}
                autoHideDuration={3000}
                onClose={() => setOpenSnackbar(false)}
                message="File Uploaded"
            />

            <ActivateDataDialog
                open={openActivateData}
                onClose={() => setOpenActivateData(false)}
            />
        </Container>
    )
}