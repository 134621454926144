import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";

import { STATUS_BAR_COLOURS } from '../constants';

export function addStatusBarLegend(chart, root) {
    const statusLegend = chart.children.push(am5.Legend.new(root, {
        nameField: 'name',
        fillField: 'color',
        strokeField: 'color',
        centerX: am5.percent(0),
        x: am5.percent(0),
        useDefaultMarker: true
    }));

    statusLegend.markers.template.setAll({
        width: 12,
        height: 12,
    });

    statusLegend.markerRectangles.template.setAll({
        cornerRadiusTL: 0,
        cornerRadiusTR: 0,
        cornerRadiusBL: 0,
        cornerRadiusBR: 0,
    });

    statusLegend.labels.template.setAll({
        fontSize: 12,
    });

    statusLegend.data.setAll([
        {
            name: 'Change Point',
            color: STATUS_BAR_COLOURS.inflection,
        },
        {
            name: 'Off-target',
            color: STATUS_BAR_COLOURS.offTarget,
        }
    ]);
}

export function addStatusBarSeries(chart, root, yAxis, data) {
    /// Has to use the same xAxis as actuals so dates match. GaplessDateAxis
    const xAxis = chart.xAxes.push(am5xy.GaplessDateAxis.new(root, {
        baseInterval: {
            timeUnit: 'day',
            count: 1
        },
        renderer: am5xy.AxisRendererX.new(root, {}),
    }));

    // Hiding the status bar's labels
    xAxis.get('renderer').labels.template.adapters.add('text', () => '');

    const series = chart.series.unshift(am5xy.ColumnSeries.new(root, {
        name: 'Change Point/Off Target Status',
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: 'value',
        valueXField: 'date',
    }));
    
    series.columns.template.setAll({
        width: am5.percent(100),
        fillOpacity: 1,
        strokeOpacity: 1,
        maxHeight: 35,
        layer: 10,
    });

    // colour the column based on whether it is an inflection/off-target or default value
    ['fill', 'stroke'].forEach(colourAttribute => {
        series.columns.template.adapters.add(colourAttribute, (fill, target) => {
            let type = target?.dataItem?.dataContext?.type;

            if (! type) {
                return STATUS_BAR_COLOURS.default;
            }

            return STATUS_BAR_COLOURS[type];
        });
    });

    series.data.setAll(data);

    return [
        series,
        xAxis,
    ];
}