import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Toolbar, Typography, IconButton, Box, ListItem, ListItemIcon, List, ListItemText, Divider } from '@mui/material'
import { useConfirm } from 'material-ui-confirm'
import MenuIcon from '@mui/icons-material/Menu';
import DashboardOutlined from '@mui/icons-material/DashboardOutlined';
import LogoutIcon from '@mui/icons-material/Logout';
import BarChartOutlined from '@mui/icons-material/BarChartOutlined';
import StackedLineChartOutlined from '@mui/icons-material/StackedLineChartOutlined';
import SettingsOutlined from '@mui/icons-material/SettingsOutlined';
import UploadFileOutlined from '@mui/icons-material/UploadFileOutlined';
import { styled, useTheme } from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';
import MuiDrawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import Close from '@mui/icons-material/Close';
import { PageFooter } from '../components/PageFooter';


const drawerWidth = 240;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
      width: `calc(${theme.spacing(9)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
  })(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
      boxSizing: 'border-box',
      ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
      }),
      ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
      }),
    }),
);
  
const Layout = ({children}) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const confirm = useConfirm();
    const [isDrawerOpened,setDrawerOpen] = useState(false);

    const logout = (() => {
        // uncoment to add confirmation before logout
        //confirm({ description: 'Are you sure you wish to logout?' })
        //    .then(() => { navigate("/login"); })
        //    .catch(() => { });
        navigate("/login");
    });

    const activeRoute = useLocation();

    const activeRouteStyling = (path, exactMatch = false) => {
        if (exactMatch && activeRoute.pathname !== path) {
            return {}
        } else if (! activeRoute.pathname.includes(path)) {
            return {}
        }

        return {
            '> *, > * > svg': {
                'color': 'secondary.main',
            },
        }
    }

    return(
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar position="fixed" open={isDrawerOpened} style={{ background: '#333333', boxShadow: "none" }}>
                <Toolbar  variant="dense" align= "center">
                    <IconButton        
                        size="small"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        onClick={() => setDrawerOpen(true)}
                        sx={{
                            color:'#fff',
                            ...(isDrawerOpened && { display: 'none' }),
                        }}>
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="subtitle1" color="#fff" component="div" sx={{ flexGrow:1, ml: -5}}>
                        MINE PERFORMANCE NAVIGATOR
                    </Typography>
                    
                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                open={isDrawerOpened}
                onClose={() => setDrawerOpen(false)}>
                <DrawerHeader sx={{ mb: 0 }}>
                    <IconButton size="small" sx={{ mt: -2 }} onClick={() => setDrawerOpen(false)}>
                        <Close />
                    </IconButton>
                </DrawerHeader>

                <List disablePadding>
                    <ListItem button key='Overview' component={Link} to="/overview" sx={ activeRouteStyling('/overview', true) }>
                        <ListItemIcon sx={{ ml: 1 }}><DashboardOutlined /></ListItemIcon>
                        <ListItemText primary='Overview' />
                    </ListItem>

                    <ListItem button key='Process Stability' component={Link} to="/process" sx={ activeRouteStyling('/process') }>
                        <ListItemIcon sx={{ ml: 1 }}><BarChartOutlined /></ListItemIcon>
                        <ListItemText primary='Process Stability' />
                    </ListItem>

                    <ListItem button key='Productivity' component={Link} to="/productivity" sx={ activeRouteStyling('/productivity') }>
                        <ListItemIcon sx={{ ml: 1 }}><StackedLineChartOutlined /></ListItemIcon>
                        <ListItemText primary='Productivity' />
                    </ListItem>

                    <Divider/>

                    <ListItem button key='Settings' component={Link} to="/settings" sx={ activeRouteStyling('/settings') }>
                        <ListItemIcon sx={{ ml: 1 }} ><SettingsOutlined /></ListItemIcon>
                        <ListItemText primary='Settings' />
                    </ListItem>

                    <ListItem button key='Data Upload' component={Link} to="/data-upload" sx={ activeRouteStyling('/data-upload') }>
                        <ListItemIcon sx={{ ml: 1 }}><UploadFileOutlined /></ListItemIcon>
                        <ListItemText primary='Data Upload' />
                    </ListItem>

                    <Divider/>

                    <ListItem button key='Logout' onClick={() => logout() }>
                        <ListItemIcon sx={{ ml: 1 }}><LogoutIcon /></ListItemIcon>
                        <ListItemText primary='Logout' />
                    </ListItem>
                </List>
            </Drawer>

            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                <DrawerHeader />
                {children}
                <PageFooter/>
            </Box>
        </Box>
    )
}

export default Layout;