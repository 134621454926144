import { format } from 'date-fns'
import { useState, useEffect } from 'react';

import DataUploadsRow from './DataUploadsRow'
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Snackbar } from '@mui/material'
import { getActiveFilenames, getDocumentList } from './DataAPI';
import formatDocumentDetails from '../utils/formatDocumentDetails';

export default function DataUploadsTable(props) {
    const { uploadedFile } = props;

    const [rows, setRows] = useState([]);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [activeFiles, setActiveFiles] = useState([]);

    const toggleActive = row => {
        let newRows = [...rows];

        const index = newRows.findIndex(r => r.id === row.id);

        newRows[index].active = ! newRows[index].active;

        setRows(newRows);
    }

    const setActiveFileState = () => {
        let updatedRows = rows.map(row => {
            row.active = (row.filename == activeFiles[row.activityType]);
            return row;
        });

        setRows(updatedRows);
    }

    const deleteRow = row => {
        const newRows = [...rows.filter(r => r.id !== row.id)];

        setRows(newRows);
        setOpenSnackbar(true);
    }

    const getDocumentRows = () => {
        let categories = [
            'Development',
            'Bogging',
            'Trucking',
            'Drilling',
            'NewUpload'
        ];

        Promise.all(categories.map(category => getDocumentList(category)))
            .then(([development, bogging, trucking, drilling, uploaded]) => {
                development = formatDocumentDetails(development.data,activeFiles['Development']);
                bogging = formatDocumentDetails(bogging.data,activeFiles['Bogging']);
                trucking = formatDocumentDetails(trucking.data,activeFiles['Trucking']);
                drilling = formatDocumentDetails(drilling.data,activeFiles['Drilling']);
                uploaded = formatDocumentDetails(uploaded.data,activeFiles['Plan']);

                let docs = [];
                docs = docs.concat(development,bogging,trucking,drilling,uploaded);

                setRows(docs);
                console.log('Document records loaded');
                getActiveFilenames(setActiveFiles);
            })
            .catch(errors => {
                console.log(errors);
            });

        
    }

    useEffect(() => {
        if (! uploadedFile) {
            return;
        }

        getDocumentRows();
        
    }, [uploadedFile]);

    useEffect(() => {
        console.log("Active :",activeFiles);
        setActiveFileState();
    }, [activeFiles])

    //Retrive document records
    useEffect(() => {
        
        getDocumentRows();    
    },[]);

    return (
        <>
            <TableContainer>
                <Table aria-label="Data Uploads Table">
                    <TableHead>
                        <TableRow>
                            {columns.map(column => (
                                <TableCell sx={{ fontWeight: 700, ...column?.sx }} align={column.align} key={column.label}>
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {rows.map(row => (
                            <DataUploadsRow
                                key={row.id}
                                row={row}
                                onActiveChange={toggleActive}
                                onDeletion={deleteRow}
                            />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={() => setOpenSnackbar(false)}
                message="File Deleted"
            />
        </>
    );
}

const columns = [
    {
        align: 'center',
        label: 'Status',
    },
    {
        align: 'left',
        label: 'Filename',
    },
    {
        align: 'left',
        label: 'Activity Type',
    },
    {
        align: 'left',
        label: 'Date Range',
    },
    {
        align: 'left',
        label: 'Modified',
    },
    {
        align: 'left',
        label: 'Modified By',
        sx: {
            pl: 7.5,
        }
    },
    {
        align: 'right',
        label: 'Detail View',
    },
]

const fakeRows = [
    {
        id: 1,
        active: true,
        filename: 'Jumbo_1.1.-1.12.2021',
        activityType: 'Development',
        dateRange: '1.1.2021 - 1.12.2021',
        modified: '2.3.2021',
        modifiedByName: 'Geoff Jones',
        modifiedByEmail: 'geoff@gmail.com',
        dataFileDescription: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Nesciunt nihil animi suscipit ipsam odio architecto voluptas aut natus, quae ipsa molestiae quia molestias sed',
    },
    {
        id: 2,
        active: true,
        filename: 'Long_Drill_1.1.-1.12.2021',
        activityType: 'Long Hole Drilling',
        dateRange: '1.1.2021 - 1.12.2021',
        modified: '1.3.2021',
        modifiedByName: 'Joe Fuller',
        modifiedByEmail: 'joe@gmail.com',
        dataFileDescription: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Nesciunt nihil animi suscipit ipsam odio architecto voluptas aut natus, quae ipsa molestiae quia molestias sed',
    },
    {
        id: 3,
        active: false,
        filename: 'Bogging_1.1.-1.12.2021',
        activityType: 'Bogging',
        dateRange: '1.1.2021 - 1.12.2021',
        modified: '2.3.2021',
        modifiedByName: 'Matt Walker',
        modifiedByEmail: 'matt@gmail.com',
        dataFileDescription: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Nesciunt nihil animi suscipit ipsam odio architecto voluptas aut natus, quae ipsa molestiae quia molestias sed',
    },
    {
        id: 4,
        active: true,
        filename: 'Trucking_1.1.-1.12.2021',
        activityType: 'Trucking',
        dateRange: '1.1.2021 - 1.12.2021',
        modified: '1.3.2021',
        modifiedByName: 'Sue Falken',
        modifiedByEmail: 'sue@gmail.com',
        dataFileDescription: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Nesciunt nihil animi suscipit ipsam odio architecto voluptas aut natus, quae ipsa molestiae quia molestias sed',
    },
]