import Login from './views/Login'
import DashboardView from './views/DashboardView';
import NotFound from './views/NotFound';
import { BrowserRouter, Route, Routes, Navigate, useLocation, useNavigate } from 'react-router-dom';
import React, { useEffect } from 'react';
import ProtectedContent from './views/ProtectedContent'
import ProcessStabilityView from './views/ProcessStabilityView';
import SettingsView from './views/SettingsView';
import UploadsView from './views/UploadsView';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { grey, deepPurple, lightGreen, red } from '@mui/material/colors';
import './Styles/App.css';
import store from './store/Store';
import ProductivityView from './views/ProductivityView';

const theme = createTheme({
    palette: {
      primary: {
        light: grey[200],
        main: grey[500],
        dark: grey[700],
        contrastText: '#000',
      },
      secondary: {
        light: deepPurple[50],
        main: deepPurple[500],
        dark: deepPurple[900],
        contrastText: '#fff',
      },
      success: {
        main: lightGreen[500],
        contrastText: '#fff',
      },
      error: {
        main: red[400],
        contrastText: '#fff',
      },
      info: {
        main: '#1976d2',
        contrastText: '#fff',
      }
    },
    typography: {
        allVariants: {
            fontFamily: [
                'Lato',
                'Roboto',
                '"Helvetica Neue"',
                'Arial',
                'sans-serif'
            ].join(','),
        },
    },
  });

export default function App() {

    /* useEffect(() => {
      console.log("App mounted");
      //Clear security token on app load
      let state = store.getState();
      store.dispatch({ type: 'security/setJwt', payload: { jwt: '', local_storage_key: state.common.local_storage_key }})
    },[]); */

    return (
        <div>
            <ThemeProvider theme={theme}>
                <BrowserRouter basename={window.location.pathname || ''}>
                    <Routes>
                        <Route 
                            path="/" element={<Login />}>
                        </Route>
                        <Route 
                            path="/overview" element={<ProtectedContent content={<DashboardView />}/>}>
                        </Route>
                        <Route 
                            path="/process" element={<ProtectedContent content={<ProcessStabilityView />}/>}>
                        </Route>
                        <Route 
                            path="/productivity" element={<ProtectedContent content={<ProductivityView />}/>}>
                        </Route>
                        <Route 
                            path="/settings" element={<ProtectedContent content={<SettingsView />}/>}>
                        </Route>
                        <Route 
                            path="/data-upload" element={<ProtectedContent content={<UploadsView />}/>}>
                        </Route>
                        <Route path="/login" element={<Login />}></Route>
                        <Route path="*" element={<NotFound />}></Route>
                    </Routes>
                </BrowserRouter>
            </ThemeProvider>
        </div>
    )
}
