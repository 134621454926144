import { createSlice } from '@reduxjs/toolkit'

import { PRODUCTION_URL, STAGING_URL } from '../constants';

export const commonSlice = createSlice({
  name: 'common',
  initialState: {
    //baseUrl: process.env.NODE_ENV === 'production' ? PRODUCTION_URL : STAGING_URL,
    baseUrl: PRODUCTION_URL,
    local_storage_key: 'akumen'
  },
  reducers: {
    setBaseUrl:(state,action) => {
      if (action.payload.baseUrl === 'production') {
        state.baseUrl =  PRODUCTION_URL;
      } else {
        //default is staging
        state.baseUrl = STAGING_URL;
      }  
    }
  },
})

export default commonSlice.reducer