import PropTypes from 'prop-types';
import { Stack } from '@mui/material'

import DashboardStatistic from './DashboardStatistic';

export default function DashboardStatistics(props) {
    const { actualProductivity,targetProductivity, forecast, forecastedTargetSum } = props;

    // todo - skipping these for now (if greater than 0 it will render)
    const targetToDate = 0;

    return (
        <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={{ xs: 4, sm: 6, md: 16 }}
            sx={{ py: { xs: 4, md: 8 } }}
            justifyContent="center"
            alignItems={{ sm: 'center' }}
        >
            <DashboardStatistic
                targetLabel="target to date"
                target={targetProductivity}
                comparisonLabel="Target vs Actual To Date"
                label="Actual Productivity"
                statistic={actualProductivity}
            />

            <DashboardStatistic
                targetLabel="Target next 3 months"
                target={forecastedTargetSum}
                comparisonLabel="Forecast VS Target"
                label="Forecast"
                statistic={forecast}
            />
        </Stack>
    );
}

DashboardStatistics.propTypes = {
    actualProductivity: PropTypes.number.isRequired,
    forecast: PropTypes.number.isRequired,
    forecastedTargetSum: PropTypes.number.isRequired,
 }