import { ConfirmProvider } from 'material-ui-confirm';
import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import store from '../store/Store';
import Layout from './Layout';

/* simplify layout of the main routing page
{content} is returned if the session is athenticated
Otherwise the brouser is redirected to /login

*/

export default function ProtectedContent(props) {

    const isSecure = () => {
        
        // Setup the jwt from local storage
        let state = store.getState();
        let local_storage_key = state.common.local_storage_key;
        let local_storage_jwt = localStorage.getItem(`${local_storage_key}`);
        if (local_storage_jwt !== null && local_storage_jwt !== undefined) {
            store.dispatch({ type: 'security/setJwt', payload: { jwt: local_storage_jwt, local_storage_key: local_storage_key }});
        }
        else {
            store.dispatch({ type: 'security/setJwt', payload: { jwt: '', local_storage_key: '' }});
        }

        state = store.getState();
  
        return state.security.jwt !== '';    
    }

    const location = useLocation();

    return(
        <ConfirmProvider>
            <Layout>
                {isSecure() ? props.content : 
                <Navigate to="/login" replace state={{ from: location }} />}
            </Layout>
        </ConfirmProvider>
    )
}
    
