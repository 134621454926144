import React  from 'react';
import { Container, Box, Typography, Stack } from '@mui/material';
import { styled } from '@mui/system';
import ActivityNote, { NewActivityNote } from './ActivityNote';

import { OFF_TARGET_COLOUR } from '../constants';

const DetailTitle = styled(Typography)(({theme}) => ({
    fontSize: 12,
    marginBottom: 10,
    fontWeight: 'bolder'  
}));

const DetailLabel = styled(Typography)(({theme}) => ({
    fontSize: 11,
    color: 'grey'  
}));

const DetailValue = styled(Typography)(({theme}) => ({
    fontSize: 13
}));

const StatusDate = styled(Typography)(({theme}) => ({
    fontSize: 11  
}));

const formatNum = (number) => {
    let str = number.toFixed(0);
    if (Number.parseFloat(str) != number )
        str = number.toFixed(1);
    if (number > 100 ) 
        str = number.toFixed(0)
    if (number > 10000 ) {
        str = (number/1000).toFixed(1) + 'k' 
    }        
    return str;
}

function StatusBar(props) {
    let statusColour = 'lightgray';
    let isOffTarget = (props.actual < props.target);
    if (isOffTarget) statusColour = OFF_TARGET_COLOUR
    if (props.hasInflection) statusColour = 'skyblue';

    return (  
            <>      
            <Box display='flex' component="span" 
               sx={{minHeight:50, p: 1, border: '1px solid lightgrey'}}>
                <Stack sx={{mr:1}}>
                    <DetailLabel>Target</DetailLabel>
                    <DetailValue><b>{formatNum(props.target)}</b></DetailValue>
                </Stack>
                <Stack>
                    <DetailLabel >Actual</DetailLabel>
                    <DetailValue sx={{color: isOffTarget ? OFF_TARGET_COLOUR: ''}}><b>{formatNum(props.actual)}</b></DetailValue>
                </Stack>
                
            </Box>
            <Box display='flex' component="span"
               sx={{minHeight:5,backgroundColor: props.seriesColour}}>
            </Box>
            <Box display='flex' component="span"
               sx={{minHeight:10,backgroundColor: statusColour}}>
            </Box>
            <Box display='flex' component="span" 
               sx={{
                   minHeight:20, 
                   p: 1,
                   backgroundColor: props.selected ? '#673ab7' : ''
                   }}>
                <StatusDate color={props.selected ? '#ffffff' : ''}>
                    {props.dateLabel}
                </StatusDate>
            </Box>
            </>
    )
}



function MinimisedDetails(props) {
    return (        
        <Box disableGutters={true} sx={{width:250}}>
           {StatusBar(props)}
        </Box>
    )
}

function FullDetails(props) {
    const { notes,noteModified, ...other } = props;
    const [noteCount, setNoteCount] = React.useState(notes.length);
    
    const AddNote = (note) => {
        
        notes.push(note);
        console.log(notes);
        //Force redraw
        setNoteCount(notes.length);

        if ( noteModified ) noteModified(note);
    }

    const UpdateNote = (note) => {
        
        if ( noteModified ) noteModified(note);
    }

    return (   
        <Container disableGutters={true} sx={{minWidth:100}}>
            <Box display='flex' component="span" 
               sx={{minHeight:100, p: 1,  border: '1px solid lightgrey'}}>
                                 
                    <Stack justifyContent="flex-start" alignItems="flex-start">
                        <DetailTitle >{props.activity} Details</DetailTitle>

                        <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
                            <DetailLabel >Crew: </DetailLabel>
                            <DetailValue ><b>{formatNum(props.crew)}</b> operators </DetailValue>
                        </Stack>
                        <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
                            <DetailLabel >Machinery:</DetailLabel> 
                            <DetailValue > <b>{formatNum(props.machineCount)}</b> {props.machineType}</DetailValue>
                        </Stack>
                        <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
                            <DetailLabel >Prod hours: </DetailLabel>
                            <DetailValue >-</DetailValue>
                        </Stack>
                        
                    </Stack>               
            </Box>
            {notes.length !== 0 ? <> 
            {
                notes.map((n,i,array) => {
                    return <ActivityNote key={i+props.date} note={n} noteModified={UpdateNote}/>
            })}           
            </> : 
            <NewActivityNote activity={props.activity} date={props.date} noteAddedCallback={AddNote} />
            }
            {StatusBar(props)}
        </Container>
    )    
}

function ActivityDetails(props)  {

    return (   
        <>
        {props.minimised ? <MinimisedDetails {...props}/> : <FullDetails {...props}/> }
        </>
    )
}

export default ActivityDetails;