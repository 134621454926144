import { useState } from 'react';
import PropTypes from 'prop-types';
import { TableRow, TableCell, Button } from '@mui/material'

export default function SettingsFieldRow(props) {
    const { fieldLabel, fieldValue } = props;

    const [showEdit, setShowEdit] = useState(false);

    const handleButtonClick = () => {
        setShowEdit(! showEdit);
    }

    return (
        <TableRow sx={{ '& td, & th': { backgroundColor: showEdit ? '#f6f6f6' : 'white' } }}>
            <TableCell sx={{ width: { md: 300 } }}>
                { fieldLabel }
            </TableCell>

            <TableCell>
                {
                    showEdit ? props.children : fieldValue
                }
            </TableCell>

            <TableCell align="right">
                <Button
                    size="small"
                    variant={showEdit ? 'contained' : 'outlined'}
                    color="secondary"
                    onClick={handleButtonClick}
                >{ showEdit ? 'Save' : 'Edit' }</Button>
            </TableCell>
        </TableRow>
    )
}

SettingsFieldRow.propTypes = {
    fieldLabel: PropTypes.string.isRequired,
    fieldValue: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]).isRequired,
};