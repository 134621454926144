import { createSlice } from '@reduxjs/toolkit'

export const securitySlice = createSlice({
  name: 'security',
  initialState: {
    jwt: '',
  },
  reducers: {
    setJwt: (state, action) => {
      if (action.payload.jwt === '') {
        localStorage.removeItem(action.payload.local_storage_key);
      }
      else {
        localStorage.setItem(action.payload.local_storage_key, action.payload.jwt);
      }
      return {
        jwt: action.payload.jwt
      }
    },
  },
})

// Action creators are generated for each case reducer function
export const { setJwt } = securitySlice.actions

export default securitySlice.reducer