import React  from 'react';
import { Box, Typography, Stack, Button, Dialog, DialogTitle, DialogContent, DialogActions, IconButton, RadioGroup, FormControlLabel, Radio, Checkbox, TextField, Divider, Chip } from '@mui/material';
import { styled } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import AddSharpIcon from '@mui/icons-material/AddSharp';
import { SaveActivityNote, UpdateActivityNote, UpdateOrCreateActivityNote } from './DataAPI';
import { format } from 'date-fns';

const NoteTitle = styled(Typography)(({theme}) => ({
    fontSize: 12,
    fontWeight: 'bolder'
}));

const NoteBody = styled(Typography)(({theme}) => ({
    fontSize: 11   
}));

const AddNoteButton = styled(Button)(({theme}) => ({
    fontSize: 10,
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 2,
    paddingBottom: 2,
    minWidth: 44,
}));

const categories = [
    {value: 'No work', info: '- equipment not required'},
    {value: 'No operator', info: ''},
    {value: 'No equipment', info: '- shortfall, maintenance'},
    {value: 'No service', info: '- power, water, air, ventilation' },
    {value: 'No access', info: '- survey, blasting, fumes/gas, ground support, congestion'},
    {value: 'No materials', info: '- bits, rods, bolts, mesh, explosives'}
];

function AddNoteDialog(props) {
    const { onClose, 
        selected, 
        comment: commentProp,  open, activity, ...other } = props;
    const [comment, setComment] = React.useState(commentProp);
    const [checked, setChecked] = React.useState({
        'No work' : false,
        'No operator' : false,
        'No equipment': false,
        'No service': false,
        'No access': false,
        'No materials': false    
    });
    
    React.useEffect(() => {
      if (!open) {
        setComment(commentProp)

        //Set checked states TODO should be able to do this in one pass ?
        let checkedState = checked;
        for (let check of Object.keys(checkedState)){
            checkedState[check] = false
        }
        selected.forEach((value)=>{
            checkedState[value] = true;             
        })
        setChecked( checkedState );
        
      }
    }, [checked, commentProp, open]);
  
    const handleEntering = () => {
        // let checkedState = checked;
        // selected.forEach((value)=>{
        //     checkedState[value] = true;             
        // })
        // setChecked( checkedState );
    };
  
    const handleCancel = () => {
        
        setComment('');
        onClose();
    };
  
    const handleOk = () => {
      onClose(checked,comment);
      setComment('');
    };
  
    const handleChange = (event) => {
      
      setChecked({ ...checked, [event.target.value] : event.target.checked})  
      
    };

    const commentChange = (event) => {
        setComment(event.target.value);
    }
  
    return (
      <Dialog
        maxWidth="sm"
        TransitionProps={{ onEntering: handleEntering }}
        fullWidth
        open={open}
        {...other}
      >
        <AddNoteDialogTitle id="add-note-dialog-title" onClose={handleCancel}>
            <Typography fontSize={20} fontWeight={'bolder'} sx={{mb:2}}>SHIFT OBSERVATIONS</Typography>
            <Typography fontSize={18}>{activity} Issues</Typography>  
        </AddNoteDialogTitle>
        <Divider variant="middle" sx={{border: '1px solid black'}}/>
        <DialogContent id="add-note-dialog-content" sx={{ pb:1}}>
            {categories.map((category) => (
            <Stack>
            <Stack direction="row" justifyContent="flex-start" alignItems="center">
              <FormControlLabel
                value={category.value}
                key={category.value}
                control={<Checkbox checked={checked[category.value]} onChange={handleChange} color='secondary'/>}
                label={<Typography variant='subtitle2'><b>{category.value}</b></Typography>}
              />
              <Typography variant='subtitle2'> {category.info}</Typography>
            </Stack>
            <Divider/>
            </Stack>
            ))}
          <TextField sx={{mt:2,mb:2}}
            fullWidth
            label="Notes"
            multiline
            rows={3}
            value={comment}
            onChange={commentChange}
        />
        </DialogContent>
        <DialogActions id="add-note-dialog-actions"  sx={{ mb:3, mt:0 }}>
          <Button sx={{mr:2}} variant='outlined' color='secondary' autoFocus onClick={handleCancel}>
            Cancel
          </Button>
          <Button sx={{width:85, mr:24}} variant='contained' color='secondary' onClick={handleOk}>Save</Button>
        </DialogActions>
      </Dialog>
    );
  }

const AddNoteDialogTitle = (props) => {
    const {children, onClose, ...other} = props;

    return (
        <DialogTitle  {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label='close'
                    onClick={onClose}
                    sx={{position: 'absolute', right:8, top:8}}
                    >
                    <CloseIcon />
                </IconButton>
            ): null}
        </DialogTitle>
    );
}

const GetSelectedValues = (checkedValues)  => {

    const valuesArray = Object.entries(checkedValues);
    let categories = [];
    valuesArray.forEach(([category,checked]) => {
    if (category != '' && checked)
        categories.push(category);
    });

    return categories;
}

function NewActivityNote(props) {
    const { activity, date, noteAddedCallback, ...other } = props;
    const [dialogOpen, setDialogOpen] = React.useState(false);
    
    const handleClose = (newValues,comment) => {
        setDialogOpen(false);

        if (newValues) {            
            let newNote = {
                activity: activity,
                date: date,
                categories: GetSelectedValues(newValues),
                comment: comment,
                shift: 'day'
            };
            //TODO re-enable save to backend store
            SaveActivityNote(newNote);
            //TODO error checking ??
            if ( noteAddedCallback ) noteAddedCallback(newNote);      
        }
    }

    const openDialog = () => {
        //Clear value
        setDialogOpen(true);
    }

    return (
        <Box display='flex' component="span" 
               sx={{minHeight:40, p: 1,  border: '1px solid lightgrey'}}>
            <Stack direction='row' alignItems='center' justifyContent='space-between' sx={{width:'100%'}}>
                <NoteTitle>Observed Issues</NoteTitle>
                <AddNoteButton 
                    variant='outlined' 
                    disableElevation
                    color='secondary'
                    onClick={openDialog}>
                        Add
                </AddNoteButton>
            </Stack>
            <AddNoteDialog open={dialogOpen}
                onClose={handleClose}
                keepMounted
                selected={[]}
                activity={activity}
                comment={undefined} />
        </Box>
    )
}

function ActivityNote(props)  {
    const { note, noteModified, ...other } = props;
    const [dialogOpen, setDialogOpen] = React.useState(false);

    const [categories, setCategories] = React.useState(note.categories);
    const [comment, setComment] = React.useState(note.comment);

    const handleClose = (newValues,comment) => {
    
        setDialogOpen(false);

        if ( newValues ) {
            setComment(comment);
            setCategories(GetSelectedValues(newValues));
            let newNote = {
                activity: note.activity,
                date: note.date,
                categories: GetSelectedValues(newValues),
                comment: comment,
                shift: 'day'
            };
            UpdateActivityNote(newNote);

            if ( noteModified ) noteModified(newNote);
        }
    }

    const openDialog = () => {
        setDialogOpen(true);
    }

    return (   
        <Box display='flex' component="span" 
            sx={{minHeight:40, p: 1,  border: '1px solid lightgrey'}}>
            <Stack sx={{width:'100%'}}>
            <Stack direction='row' alignItems='center' justifyContent='space-between' sx={{width:'100%', mb:1 }}>
                <NoteTitle >Observed Issues</NoteTitle>
                <AddNoteButton variant='outlined' 
                    disableElevation
                    color='secondary'
                    onClick={openDialog}>
                    Edit
                </AddNoteButton>
            </Stack>
            <Box>
            {categories.map((category,i)=>{
                return <Chip key={i} variant='outlined' label={category} size='small'></Chip>
            })}
            </Box>
            { comment ?
            <Box display='flex' component="span" 
                sx={{mt:1, p: 1,  border: '1px solid lightgrey'}}>
                <NoteBody>{comment}</NoteBody>
            </Box>
            : <></> }
            </Stack>
            <AddNoteDialog open={dialogOpen}
                onClose={handleClose}
                keepMounted
                selected={categories}
                activity={note.activity}
                comment={comment} />
        </Box> 
     
    )
}

export default ActivityNote;
export {NewActivityNote};
