import { useState, useEffect } from 'react';
import { Container, Typography, Box, Divider, FormControl, InputLabel, Select, MenuItem, Stack, Button, CircularProgress } from '@mui/material'

import { getForecastStartDate } from '../components/DataAPI';
import { TRUCKING_RECONCILED_SCENARIO } from '../constants';

import { eachQuarterOfInterval, endOfQuarter, format} from 'date-fns';

import DashboardChart from '../components/dashboard/DashboardChart';
import DashboardStatistics from '../components/dashboard/DashboardStatistics';

import useForecastData from '../components/dashboard/hooks/use-forecast-data';
import useProductivityData from '../components/dashboard/hooks/use-productivity-data';
import { Link } from 'react-router-dom';

export default function DashboardView() {
    const [isLoading, setIsLoading] = useState(true);
    const [timeline, setTimeline] = useState('all');
    const [selectedTimeline, setSelectedTimeline] = useState(null);
    const [forecastStartDate, setForecastStartDate ] = useState(null);
    const [timelineOptions, setTimelineOptions] = useState([
        {
            label: 'All',
            value: 'all',
        },
    ]);

    const { forecastSum, filteredForecastData, forecastData } = useForecastData(selectedTimeline, forecastStartDate);
    const {
        forecastedTargetSum,
        actualProductivitySum,
        targetProductivitySum,
        filteredProductivityData,
    } = useProductivityData(selectedTimeline, forecastStartDate);

    useEffect(() => {
        setSelectedTimeline(timelineOptions.find(option => option.value === timeline));
    }, [timeline, timelineOptions]);

    useEffect(() => {
        let timelineOptions = [
            {
                label: 'All',
                value: 'all',
            },
        ];

        if (forecastData.length === 0) {
            setTimelineOptions(timelineOptions);

            return;
        }

        eachQuarterOfInterval({
            start: forecastData[0].date,
            end: forecastData[forecastData.length - 1].date,
        }).forEach(startOfQuarter => {
            const end = endOfQuarter(startOfQuarter);

            const label = format(startOfQuarter, 'QQQ - yyyy');

            timelineOptions.push({
                label,
                value: label,
                start: startOfQuarter,
                end: end,
            });
        })

        setTimelineOptions(timelineOptions);
    }, [forecastData]);

    useEffect(() => {
        getForecastStartDate(TRUCKING_RECONCILED_SCENARIO, date => setForecastStartDate(date));
    }, []);

    // todo - temporary loading state - could actually wait for both api
    // request to complete before flicking the load state to false
    setTimeout(() => {
        setIsLoading(false);
    }, 1500);

    return (
        <Container maxWidth="2xl">
            <Typography align='left' variant="h5" component="h1" sx={{ textTransform: 'uppercase'}}>
                Mine Site Overview
            </Typography>

            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 2, sm: 0 }} alignItems={{ sm: 'center' }} sx={{ 'py': 2 }}>
                <Box sx={{ flexGrow: 1 }}>
                    <Typography align='left' variant="h6" component="h2" sx={{ textTransform: 'uppercase'}}>
                        Productivity Analysis and Forecasting Overview
                    </Typography>
                </Box>

                <Box sx={{ alignItems: 'right' }}>
                    <Typography variant="subtitle2" component="h6" sx={{ mr: '12px' }}>
                        Project Timeline View:
                    </Typography>
                </Box>

                <Box sx={{ alignItems: 'right', minWidth: '150px' }}>
                    <FormControl fullWidth>
                        <InputLabel variant="standard" id="select-timeline">&nbsp;</InputLabel>

                        <Select
                            labelId="select-timeline"
                            id="project-timeline"
                            size="small"
                            value={timeline}
                            onChange={(e) => setTimeline(e.target.value)}
                        >
                            {
                                timelineOptions.map((option, index) => (
                                    <MenuItem key={index} value={option.value}>{option.label}</MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                </Box>
            </Stack>

            <Divider />

            <DashboardStatistics
                actualProductivity={actualProductivitySum}
                targetProductivity={targetProductivitySum}
                forecast={forecastSum}
                forecastedTargetSum={forecastedTargetSum}
            />

            {
                isLoading ?
                    <Box alignItems='center' 
                        justifyContent='center' 
                        display='flex' 
                        component="span" 
                        sx={{ height:'400px', p: 2}}
                    >
                        <Stack alignItems='center'>
                            <CircularProgress color="secondary"/>
                            <Typography>Loading data ...</Typography>
                        </Stack>  
                    </Box>
                    :
                    <DashboardChart
                        forecastData={filteredForecastData}
                        productivityData={filteredProductivityData}
                        forecastStartDate={forecastStartDate}
                    />
            }

            <Divider sx={{ mb: '20px' }} />

            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 1, sm: 2, md: 4 }} justifyContent="center">
                <Button 
                    component={Link} to={'/process'}
                    disableElevation
                    color="secondary" variant="contained" href="#" size="large">
                    Explore Process Stability
                </Button>

                <Button
                    component={Link}
                    to={'/productivity'}
                    disableElevation
                    color="secondary" 
                    variant="contained" href="#" size="large">
                    Analyse Productivity
                </Button>
            </Stack>
        </Container>
    )
}