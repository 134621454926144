import { useRef, useLayoutEffect } from 'react';

import PropTypes from 'prop-types';

import { isBefore, format } from 'date-fns';

import { addStatusBarLegend, addStatusBarSeries } from '../../utils/status-bar-utilities';

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

import { SELECTION_COLOUR } from '../../constants';

export default function DashboardChart(props) {
    const { forecastData, productivityData, forecastStartDate } = props;

    const actualSeriesRef = useRef(null);
    const targetSeriesRef = useRef(null);
    const forecastSeriesRef = useRef(null);
    const statusBarSeriesRef = useRef(null);

    const actualData = productivityData
        .filter(item => isBefore(item.date, forecastStartDate)) // remove anything from the forecast period
        .map(item => {
            return {
                date: item.date,
                value: item.actual,
            }
        });

    const targetData = productivityData.map(item => {
        return {
            date: item.date,
            value: item.target,
        }
    });

    const statusBarData = productivityData.map(item => {
        let type = 'default';

        if (item.offTarget) {
            type = 'offTarget';
        }

        if (item.hasInflection) {
            type = 'inflection';
        }

        return {
            type,
            date: item.date,
            value: 350,
        };
    });

    useLayoutEffect(() => {
        let root = am5.Root.new('chart');

        root.setThemes([
            am5themes_Animated.new(root),
            CustomTheme.new(root)
        ]);

        const [chart, yAxis, xAxis] = createChart(root);

        createSeries({chart, root, yAxis, xAxis}, targetData, 'Target Output', targetSeriesRef, {
            stroke: '#f00',
            tooltipColor: '#1a1a1a',
        }, {
            strokeDasharray: [6, 6],
            strokeWidth: 1,
        });

        createSeries({chart, root, yAxis, xAxis}, actualData, 'Actual Output', actualSeriesRef, {
            stroke: '#1a1a1a',
            tooltipColor: '#fff',
        });

        createSeries({chart, root, yAxis, xAxis}, forecastData, 'Forecast', forecastSeriesRef, {
            stroke: '#1a91e7',
            tooltipColor: '#fff',
        });

        const legendRoot = createLegend(chart, root)

        createStatusBar(chart, root, yAxis, statusBarData);

        try {
            createForecastRange(xAxis);
            createTodayLine(xAxis);
        }
        catch (e) {
            console.log("Error diplaying forcast range");
            //TODO, create forcast range after error ?
        }

        return () => {
            root.dispose();
            legendRoot.dispose();
        };
    }, []);

    useLayoutEffect(() => {
        actualSeriesRef.current.data.setAll(actualData);
    }, [actualData]);

    useLayoutEffect(() => {
        targetSeriesRef.current.data.setAll(targetData);
    }, [targetData]);

    useLayoutEffect(() => {
        forecastSeriesRef.current.data.setAll(forecastData);
    }, [forecastData]);

    useLayoutEffect(() => {
        statusBarSeriesRef.current.data.setAll(statusBarData);
    }, [statusBarData]);

    const createChart = (root) => {
        let chart = root.container.children.push( 
            am5xy.XYChart.new(root, {
                panY: false,
                layout: root.verticalLayout,
            }) 
        );

        let yAxis = chart.yAxes.push(
            am5xy.ValueAxis.new(root, {
                renderer: am5xy.AxisRendererY.new(root, {})
            })
        );

        yAxis.hide();

        let xAxis = chart.xAxes.push(
            am5xy.DateAxis.new(root, {
                baseInterval:{
                    timeUnit: 'day',
                    count: 1
                },
                renderer: am5xy.AxisRendererX.new(root, {}),
                groupData: false,
                tooltipDateFormat: "EEE dd.MM",
            })
        );

        xAxis.get('dateFormats')['month'] = 'MMMM YYYY';

        // prevents xAxis labels being cut off
        xAxis.get('renderer').labels.template.setAll({
            minPosition: 0.05,
            maxPosition: 0.95
        });

        let tooltip = am5.Tooltip.new(root, {
            themeTags: ["axis"]
        });

        tooltip.get('background').setAll({
            fill: am5.color(SELECTION_COLOUR)
        })

        // this adds the xAxis date to the cursor
        xAxis.set('tooltip', tooltip);

        // Add cursor
        let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
            xAxis: xAxis,
        }));

        cursor.lineY.setAll({
            visible: false
        });

        return [
            chart,
            yAxis,
            xAxis,
        ];
    }

    const createSeries = ({chart, root, yAxis, xAxis}, data, seriesName, seriesRef, configuration = {}, strokeConfiguration = {}) => {
        let series = chart.series.push(am5xy.LineSeries.new(root, {
            name: seriesName,
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: 'value',
            valueXField: 'date',
            ...configuration
        }));

        let tooltip = am5.Tooltip.new(root, {
            labelText: "["+configuration.stroke+"]●[/] {name} [bold]{valueY.formatNumber('#.0a')}[/]",
            getFillFromSprite: false,
            autoTextColor: false,
        });

        tooltip.get("background").setAll({
            fill: am5.color(0xffffff),
            stroke: configuration.stroke,
            fillOpacity: 0.8
        });

        tooltip.label.setAll({
            fill: am5.color(0x000000)
        })

        series.set('tooltip', tooltip);

        series.data.setAll(data);

        seriesRef.current = series;

        series.strokes.template.setAll({
            strokeWidth: 2,
            ...strokeConfiguration,
        });

        return series;
    }

    const createLegend = (chart, root) => {
        // Using a separate container to sit the legend above the chart
        let legendRoot = am5.Root.new('legend');

        legendRoot.setThemes([
            am5themes_Animated.new(root),
            am5xy.DefaultTheme.new(legendRoot),
        ]);

        // Add legend
        let legend = legendRoot.container.children.push(
            am5.Legend.new(legendRoot, {
                width: am5.percent(100),
                centerX: am5.percent(0),
                x: am5.percent(0),
            })
        );

        legend.markers.template.setAll({
            width: 16,
            height: 12,
        });

        legend.labels.template.setAll({
            fontSize: 12,
        });

        legend.data.setAll(chart.series.values);

        return legendRoot;
    }

    const createStatusBar = (chart, root, yAxis, data) => {
        const [series] = addStatusBarSeries(chart, root, yAxis, data);

        statusBarSeriesRef.current = series;

        addStatusBarLegend(chart, root);
    }

    const createForecastRange = xAxis => {
        const forecastRange = xAxis.makeDataItem({
            value: forecastStartDate,
            endValue: forecastData[forecastData.length - 1].date
        });

        xAxis.createAxisRange(forecastRange);

        forecastRange.get('axisFill').setAll({
            visible: true,
            fillOpacity: 0.1,
            fill: am5.color("rgb(0,181,226)")
        });
    }

    const createTodayLine = xAxis => {
        const todayLine = xAxis.makeDataItem({
            value: forecastStartDate,
            endValue: forecastStartDate
        });

        let range = xAxis.createAxisRange(todayLine);

        range.get('grid').setAll({
            stroke: 'red',
            location: 0,
            strokeOpacity: 1,
        });

        range.get('label').setAll({
            fontSize: 10,
            text: `Forecast ${format(forecastStartDate, 'd.M.yy')}`,
            location: 1,
        });
    }

    return (
        <>
            <div id="legend" style={{ width: '100%', height: '30px' }}></div>
            <div id="chart" style={{ width: '100%', height: '400px' }}></div>
        </>
    );
};

class CustomTheme extends am5.Theme {
    setupDefaultRules() {
        this.rule('Label', ['axis']).setAll({
            fontSize: 12,
        });
    }
}

DashboardChart.propTypes = {
    forecastData: PropTypes.array.isRequired,
    productivityData: PropTypes.array.isRequired,
}