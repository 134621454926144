import { useState, useEffect } from 'react';
import { isBefore, isWithinInterval } from 'date-fns';
import { getDailyActivityDetails } from '../../DataAPI';

import { TRUCKING_RECONCILED_SCENARIO } from '../../../constants';

import formatActivityDetailsData from '../../../utils/format-activity-details-data';

export default function useProductivityData(selectedTimeline, forecastStartDate) {
    const [isLoadingProductivityData, setIsLoadingProductivityData] = useState(true);
    const [productivityData, setProductivityData] = useState([]);
    const [filteredProductivityData, setFilteredProductivityData] = useState([]);

    const actualProductivitySum = filteredProductivityData
        .filter(item => isBefore(item.date, forecastStartDate)) // only summing actual productivity before the forecast period
        .reduce((previousValue, currentValue) => {
            return previousValue + currentValue.actual;
        }, 0);
    
    //Get total targeted production
    const targetProductivitySum = filteredProductivityData
        .filter(item => isBefore(item.date, forecastStartDate)) 
        .reduce((previousValue, currentValue) => {
            return previousValue + currentValue.target;
        }, 0);

    const forecastedTargetSum = filteredProductivityData
        .filter(item => ! isBefore(item.date, forecastStartDate)) // only summing targets within the forecast period
        .reduce((previousValue, currentValue) => {
            return previousValue + currentValue.target;
        }, 0);

    // Fetch initial data from the API
    useEffect(() => {
        getDailyActivityDetails(TRUCKING_RECONCILED_SCENARIO)
            .then(({ data }) => {
                setProductivityData(formatActivityDetailsData(data.DataValues));

                setIsLoadingProductivityData(false);
            });
    }, []);

    // Filter the Productivity data by the selected timeline
    useEffect(() => {
        if (isLoadingProductivityData) {
            return;
        }

        if (selectedTimeline.value === 'all') {
            setFilteredProductivityData(productivityData);

            return;
        }

        const filteredData = productivityData.filter(item => isWithinInterval(item.date, { start: selectedTimeline.start, end: selectedTimeline.end }));

        setFilteredProductivityData(filteredData);
    }, [selectedTimeline, isLoadingProductivityData, productivityData]);

    return {
        filteredProductivityData,
        actualProductivitySum,
        targetProductivitySum,
        forecastedTargetSum,
    }
}