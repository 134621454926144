import { useState } from 'react';
import { TableContainer, Table, TableBody } from '@mui/material'

import SettingSelect from './SettingSelect';
import SettingInput from './SettingInput';
import SettingsHeadingRow from './SettingsHeadingRow';
import SettingsFieldRow from './SettingsFieldRow';

export default function SettingsTable() {
    const [settings, setSettings] = useState({
        productivity_unit: 'tkm',
        projection_length: '2 months',
        color_scheme: 'Color blind friendly',
        email: 'johndoe@gmail.com',
        password: 'secret',
    });

    const productivityUnits = [
        { value: 'tkm', label: 'Tons per kilometre' },
        { value: 'kgm', label: 'Kilograms per metre' },
        { value: 'gkm', label: 'Grams per kilometre' },
    ];

    const projectionLengths = [
        { value: '1 month', label: '1 month' },
        { value: '2 months', label: '2 months' },
        { value: '3 months', label: '3 months' },
        { value: '6 months', label: '6 months' },
    ];

    const colourSchemes = [
        { value: 'Normal', label: 'Normal' },
        { value: 'High Contrast', label: 'High Contrast' },
        { value: 'Color blind friendly', label: 'Color blind friendly' },
    ];

    return (
        <>
            <TableContainer>
                <Table aria-label="Settings Table">
                    <TableBody>
                        <SettingsHeadingRow>Data and Metrics</SettingsHeadingRow>

                        <SettingsFieldRow fieldLabel="Productivity Unit" fieldValue={settings.productivity_unit}>
                            <SettingSelect
                                options={productivityUnits}
                                inputProps={{ 'aria-label': 'Productivity Unit' }}
                                value={settings.productivity_unit}
                                onChange={e => setSettings({ ...settings, productivity_unit: e.target.value })}
                            />
                        </SettingsFieldRow>

                        <SettingsHeadingRow>Forecasting</SettingsHeadingRow>

                        <SettingsFieldRow fieldLabel="Projection length" fieldValue={settings.projection_length}>
                            <SettingSelect
                                options={projectionLengths}
                                inputProps={{ 'aria-label': 'Projection Length' }}
                                value={settings.projection_length}
                                onChange={e => setSettings({ ...settings, projection_length: e.target.value })}
                            />
                        </SettingsFieldRow>

                        <SettingsHeadingRow>Application</SettingsHeadingRow>

                        <SettingsFieldRow fieldLabel="Color scheme" fieldValue={settings.color_scheme}>
                            <SettingSelect
                                options={colourSchemes}
                                inputProps={{ 'aria-label': 'Color Scheme' }}
                                value={settings.color_scheme}
                                onChange={e => setSettings({ ...settings, color_scheme: e.target.value })}
                            />
                        </SettingsFieldRow>

                        <SettingsHeadingRow>User</SettingsHeadingRow>

                        <SettingsFieldRow fieldLabel="Email" fieldValue={settings.email}>
                            <SettingInput
                                type="email"
                                value={settings.email}
                                onChange={e => setSettings({ ...settings, email: e.target.value })}
                            />
                        </SettingsFieldRow>

                        <SettingsFieldRow fieldLabel="Password" fieldValue={settings.password.replaceAll(/[a-zA-Z0-9\s]/g, '*')}>
                            <SettingInput
                                value={settings.password}
                                onChange={e => setSettings({ ...settings, password: e.target.value })}
                            />
                        </SettingsFieldRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}