import PropTypes from 'prop-types';

import { useEffect, useState } from 'react';
import { TableRow, IconButton, Collapse, TableCell, Box, TextField, Badge, Tooltip, Button, DialogContentText, Input, Stack, CircularProgress } from '@mui/material';

import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import { executeScenarios, setFileInputParam, uploadFileToDocManager } from './DataAPI';

export default function ActivityDataSourceRow(props) {
    const { row, onActiveChange, onMonitorStatus } = props;
    const [open, setOpen] = useState(false);
    const [uploading,setUploading] = useState(false);

    const handleDataUpload = (row) => {
        console.log(row.activity, ' upload');

    }

    const uploadActivityFile = (fileObj,activity) => {
        
        setUploading(true);

        //Add selected file to document libary
        uploadFileToDocManager(fileObj,activity)
        .then(response => {
            
            //setUploadingFile(false);
            
            console.log('Upload reponse ->',response);

            //Set file as current file
            let documentId = response.data[0];
            setFileInputParam(documentId,fileObj,activity)
            .then(response => {
                console.log('Param Set - ',response);
                
                //Set filename
                row.filename = fileObj.name;
                setUploading(false);
            })
            .catch(error =>{
                console.log('INPUT PARAM ERROR' , error);
                setUploading(false);
            });

        })
        .catch(error => {
            //Upload error
            console.log(error);
            setUploading(false);
        })
    }

    useEffect(() => {

        onActiveChange(row);    

    },[uploading]);

    return (
        <>
            <TableRow sx={{ '& td, & th': { borderColor: 'white', backgroundColor: open ? '#f6f6f6' : 'white' } }}>
                <TableCell align="center" component="th" scope="row">
                    <Tooltip title={row.statusTxt ? row.statusTxt : 'unknown'}>
                        <Badge
                            badgeContent={' '}
                            color={row.runStatus ? 'success' : 'error'}
                        />
                    </Tooltip>
                </TableCell>

                <TableCell>{row.filename}</TableCell>
                <TableCell>{row.activity}</TableCell>
                <TableCell>{row.dateRange}</TableCell>

                <TableCell>{row.valueField}</TableCell>
                <TableCell>{row.mineAreaField}</TableCell>
                
                <TableCell align="right">
                    <IconButton
                        aria-label="expand row"
                        size="medium"
                        onClick={() => setOpen(!open)}
                        >
                        {open ? <RemoveIcon /> : <AddIcon />}
                    </IconButton>
                </TableCell>

            </TableRow>
            <TableRow sx={{ '& td': { backgroundColor: '#f6f6f6', paddingTop: 0, paddingBottom: 0 } }}>
                <TableCell></TableCell>

                <TableCell colSpan={5}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Stack >
                            <Box sx={{
                                display: 'grid',
                                gridTemplateColumns: {
                                    md: 'repeat(2, 1fr)',
                                },
                                gap: 2,
                                alignItems: 'end' }}
                            >
                               
                                <TextField
                                    fullWidth
                                    multiline
                                    label="Data File Description"
                                    variant="outlined"
                                    minRows={3}
                                    color="info"
                                    defaultValue={row.activity}
                                    sx={{ gridColumn: { md: 'span 2' } }}
                                />   
                            </Box>
                            <Box sx={{p:2}}>
                            <Stack direction="row" justifyContent="space-around">
                                {!uploading
                                ?
                                <label htmlFor={"row-"+row.id+"-upload"}>
                                    <Input
                                            id={"row-"+row.id+"-upload"}
                                            type="file"
                                            sx={{ display: 'none' }}
                                            onChange={(e) => {
                                                uploadActivityFile(e.target.files[0], row.scenarioName)
                                            }}
                                    />
                                    <Button
                                        disableElevation
                                        variant="contained" size="large" color="secondary" component="span">
                                        Import
                                    </Button>    
                                </label>
                                :
                                <CircularProgress color="secondary"/>
                                }
                                { row.runStatus || (row.statusTxt == 'NotRun')
                                ?
                                <Button
                                    disableElevation
                                    variant="contained" size="large" color="secondary" component="span"
                                    onClick={() => {
                                        console.log("Analyse Row ", row.scenarioName)
                                        executeScenarios([row.scenarioName]);
                                        onMonitorStatus(row);
                                    }}>
                                    Analyse
                                </Button>
                                : 
                                <>
                                { row.statusTxt == 'Error'
                                ?
                                <Button>Error</Button>
                                :
                                <CircularProgress color="secondary"/>
                                }
                                </>
                                }
                            </Stack>
                            </Box>
                        </Stack>
                    </Collapse>
                </TableCell>

                <TableCell></TableCell>
            </TableRow>            
        </>
    );
}

ActivityDataSourceRow.propTypes = {
    row: PropTypes.shape({
        id: PropTypes.number.isRequired,
        runStatus: PropTypes.bool.isRequired,
        filename: PropTypes.string.isRequired,
        activity: PropTypes.string.isRequired,
        scenarioName: PropTypes.string.isRequired,
        valueField: PropTypes.string.isRequired,
        mineAreaField: PropTypes.string.isRequired,
        dateRange: PropTypes.string.isRequired
    }).isRequired,
};