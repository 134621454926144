import React  from 'react';
import {Typography, Button, Dialog, DialogTitle, DialogContent, DialogActions, IconButton,TextField, Divider} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { executeScenarios } from './DataAPI';

function ActivateDataDialog(props) {
    const { okCallback, onClose, open, ...other } = props;
    
    React.useEffect(() => {
      if (!open) {
        
      }
    }, [open]);
  
    const handleEntering = () => {
        
    };
  
    const handleCancel = () => {
        
        onClose();
    };
  
    const handleOk = () => {
        executeScenarios(["Trucking"]);
        onClose();
    };
  
    const handleChange = (event) => {
      
    };
  
    return (
      <Dialog
        maxWidth="sm"
        TransitionProps={{ onEntering: handleEntering }}
        fullWidth
        open={open}
        {...other}
      >
        <ActivateDataDialogTitle id="activate-data-dialog-title" onClose={handleCancel}>
            <Typography fontSize={20} fontWeight={'bolder'} sx={{mb:2}}>Active data files</Typography>
        </ActivateDataDialogTitle>
        
        <DialogContent id="activate-data-dialog-content" sx={{ pb:1}}>
          <TextField sx={{mt:2,mb:2}}
            fullWidth
            label="Mine Plan"
          />
          <TextField sx={{mt:2,mb:2}}
            fullWidth
            label="Development"
          />
          <TextField sx={{mt:2,mb:2}}
            fullWidth
            label="Bogging"
          />
          <TextField sx={{mt:2,mb:2}}
            fullWidth
            label="Drilling"
          />
          <TextField sx={{mt:2,mb:2}}
            fullWidth
            label="Trucking"
          />

        </DialogContent>
        <DialogActions id="activate-data-dialog-actions"  sx={{ mb:3, mt:0 }}>
          <Button sx={{mr:2}} variant='outlined' color='secondary' autoFocus onClick={handleCancel}>
            Cancel
          </Button>
          <Button sx={{width:85, mr:24}} variant='contained' color='secondary' onClick={handleOk}>
            Activate
        </Button>
        </DialogActions>
      </Dialog>
    );
}

const ActivateDataDialogTitle = (props) => {
    const {children, onClose, ...other} = props;

        return (
        <DialogTitle  {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label='close'
                    onClick={onClose}
                    sx={{position: 'absolute', right:8, top:8}}
                    >
                    <CloseIcon />
                </IconButton>
            ): null}
        </DialogTitle>
    );
}

export default ActivateDataDialog;

