import { Container } from '@mui/material'
import Carousel from 'react-material-ui-carousel'


import slideOne from '../assets/productivity-slides/Productivity_Analysis_4_2x.jpg';
import slideTwo from '../assets/productivity-slides/Productivity_Analysis_5_2x.jpg';
import slideThree from '../assets/productivity-slides/Productivity_Analysis_6_2x.jpg';
import slideFour from '../assets/productivity-slides/Productivity_Analysis_2x.jpg';
import { PageNavButton } from '../components/PageNavButton';

export default function ProductivityView() {
    const slides = [
        slideFour,
        slideOne,
        slideTwo,
        slideThree
    ];

    return (
        <Container maxWidth="2xl">
            <PageNavButton pageTitle='Productivity Analysis' />
            <Carousel autoPlay={false} navButtonsAlwaysVisible={true}>
                {
                    slides.map((slide, i) => (
                        <img key={i} src={slide} style={{ width: '100%' }} />
                    ))
                }
            </Carousel>
        </Container>
    )
}