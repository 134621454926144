import { Box, Container, Divider, Link, Stack, Typography } from '@mui/material';
import React from 'react';
import IdobaLogo from '../assets/idoba_logo.png';
import SumitomoLogo from '../assets/sumitomo_corporation_logo.png';

function Copyright() {
    
    return (
      <Typography variant="subtitle2" color="text.secondary">
        {'Copyright © '}
        <Link href="https://idoba.com/" target="_blank" rel="noopener noreferrer">idoba</Link>
        {' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
}

export function PageFooter () {

    return (        
        <Container  maxWidth="2xl">
            <Divider fullwidth sx={{ mb: '5px' , mt: '80px'}} />
            <Stack  fullwidth justifyContent="flex-start" spacing={1} sx={{p:2}}> 
                <a href="https://idoba.com/" target="_blank" rel="noopener">          
                    <Box component="img" src={IdobaLogo} sx={{height:'20px',width:'77px'}}/>
                </a>
                <a href="https://www.sumitomocorp.com/en/asia-oceania" target="_blank" rel="noopener">
                    <Box component="img" src={SumitomoLogo} sx={{height:'20px',width:'201px'}}/>
                </a>                
                <Copyright sx={{ mt: 8, mb: 4 }}/>
            </Stack>
        </Container>
        
    )
}
