import React, { useEffect } from 'react';
import { Container, Box, Typography, Stack } from '@mui/material';
import ActivityDetails from './ActivityDetails';
import { addDays, format, subDays,subWeeks,addWeeks, getWeek, subMonths, addMonths, getMonth, subQuarters, addQuarters, getQuarter } from 'date-fns';
import CircleIcon from '@mui/icons-material/Circle';
import {getActivityNotes} from './DataAPI'

function getDayLabel(date) {
    try {
        var result = format(new Date(date), 'EEE dd.MM');
        return result;
    }
    catch(err) {
        return 'NaD'
    }
}
function getWeekLabel(date) {
    try {
        var result = "week " + getWeek(date) + "." + format(date,'yy');
        return result;
    }
    catch(err) {
        return 'NaD'
    }    
}
function getMonthLabel(date) {
    try {
        var result = format(new Date(date), 'MMM yyyy');
        return result;
    }
    catch(err) {
        return 'NaD'
    }    
}
function getQuarterLabel(date) {
    try {
        var result = format(date,'QQQ.yy');
        return result;    
    }
    catch(err) {
        return 'NaD'
    }
}


//TODO - enable scrolling 

const ActivityDetailsView = (props) => {  
    var details = props.details;
    
    const [activityNotes, setActivityNotes] = React.useState([]);

    //extract records to show. 
    // Currently show 5 records with selected rec in center

    //TODO - change form date range to n records either side of selectedDate

    //TODO - What if selected value is first / last available record
    //   show it not collapsed.

    //TODO loading spinner ??

    useEffect(() => {
        getActivityNotes(props.title,(notes) => {
            setActivityNotes(notes);
        })
    },[])

    var displayedDetails = null;

    const onNoteModified = (newnote) => {

        let notes =  activityNotes.filter((note) => {
            return (note.date === newnote.date 
                && note.activity === newnote.activity
                && note.shift === newnote.shift);    
        })
        if ( notes.length > 0 ) {
            //update note/s ? should be at most one
            notes[0].comment = newnote.comment;
            notes[0].categories = newnote.categories;
        } else {
            //add new note
            activityNotes.push(newnote);
        }
    }


    try {  
        var dateMin = subDays(props.selectedDate,2).getTime();
        var dateMax = addDays(props.selectedDate,3).getTime();
        //setup date range based on timeline selection (default is days)
        //the details data should match the timeline selection is (daily/weekly/monthy data)
        switch( props.timeline ) {
            case 'Weekly':
                dateMin = subWeeks(props.selectedDate,3).getTime();
                dateMax = addWeeks(props.selectedDate,2).getTime();
            break;
            case 'Monthly':
                dateMin = subMonths(props.selectedDate,3).getTime();
                dateMax = addMonths(props.selectedDate,2).getTime();
            break;
            case 'Quarterly':
                dateMin = subQuarters(props.selectedDate,3).getTime();
                dateMax = addQuarters(props.selectedDate,2).getTime();         
            break;
            
            default:
                    
        }
        displayedDetails = details.filter((rec) => {
            return (dateMin <= rec.date && rec.date < dateMax);    
        }) 
        
    }
    catch(err) {
        console.log(err);
    }

    return (
        
        <Container maxwidth="md">
            {displayedDetails.length !== 0 ? <>
            <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1} sx={{p:2}}>
                <CircleIcon sx={{ color: props.seriesColour}} />
                <Typography align='left' component="div" sx={{fontSize:16}}>
                    <b>{props.title}</b>  
                </Typography>
                <Typography align='left' component="div" sx={{fontSize:12}}>
                    [{props.unitDesc}] 
                </Typography>
            </Stack>
            <Box alignItems='end' display='flex' sx={{ justifyContent: "center" }}>
                {
                displayedDetails.map((rec,i,array) => {
                    var minimise = ( array.length > 2 ) && (i===0 || i === array.length -1);
                    var isSelected = (rec.date === props.selectedDate);
                    var dateLabel = '';
                    var notes =  activityNotes.filter((note) => {
                        return (note.date === rec.date);    
                    })
                    switch ( props.timeline ) {
                        case 'Daily' :
                            isSelected = (rec.date === props.selectedDate);
                            dateLabel = getDayLabel(rec.date);
                        break;
                        case 'Weekly':
                            isSelected = getWeek(rec.date) === getWeek(props.selectedDate);
                            dateLabel = getWeekLabel(rec.date);
                        break;
                        case 'Monthly':
                            isSelected = getMonth(rec.date) === getMonth(props.selectedDate);
                            dateLabel = getMonthLabel(rec.date);
                        break;
                        case 'Quarterly':
                            isSelected = getQuarter(rec.date) === getQuarter(props.selectedDate);
                            dateLabel = getQuarterLabel(rec.date);
                        break;
                        default:
                           
                    }
                    if ( props.timeline === 'Weekly' ) 
                        isSelected = getWeek(rec.date) === getWeek(props.selectedDate)
                    return <ActivityDetails key={i}
                                activity={props.title}
                                dateLabel={dateLabel} 
                                date={rec.date}
                                target={rec.target} 
                                actual={rec.actual}
                                crew={rec.crew}
                                prodHours={rec.prod_hours}
                                machineCount={rec.machinery_count}
                                machineType={props.machineType}
                                hasInflection={rec.hasInflection}
                                notes={notes}
                                selected={isSelected}
                                minimised={minimise}
                                seriesColour={props.seriesColour}
                                noteModified={onNoteModified}/>    
                })                
                } 
            </Box> </>
             : <></>}
        </Container>
    )
}

export default ActivityDetailsView;