import { useState, useEffect } from 'react';
import { getForecastData } from '../../DataAPI';

import { isBefore, isWithinInterval } from 'date-fns';

import { TRUCKING_RECONCILED_SCENARIO } from '../../../constants';

export default function useForecastData(selectedTimeline, forecastStartDate) {
    const [isLoadingForecastData, setIsLoadingForecastData] = useState(true);
    const [forecastData, setForecastData] = useState([]);
    const [filteredForecastData, setFilteredForecastData] = useState([]);

    const forecastSum = forecastData
        .filter(item => ! isBefore(item.date, forecastStartDate)) // only sum forecast data from the start of the forecast period
        .reduce((previousValue, currentValue) => {
            return previousValue + currentValue.value;
        }, 0);

    // Fetch initial data from the API
    useEffect(() => {
        getForecastData(TRUCKING_RECONCILED_SCENARIO, data => {
            setForecastData(data.map(record => {
                return {
                    date: record.date,
                    value: record.value
                }
            }));

            setIsLoadingForecastData(false);
        });
    }, []);

    // Filter the forecast data by the selected timeline
    useEffect(() => {
        if (isLoadingForecastData) {
            return;
        }

        if (selectedTimeline.value === 'all') {
            setFilteredForecastData(forecastData);

            return;
        }

        setFilteredForecastData(
            forecastData.filter(item => isWithinInterval(item.date, { start: selectedTimeline.start, end: selectedTimeline.end }))
        );
    }, [selectedTimeline, isLoadingForecastData, forecastData]);

    return {
        forecastSum,
        filteredForecastData,
        forecastData,
    }
}