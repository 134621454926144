import SettingsTable from '../components/settings/SettingsTable';
import { Container, Typography, Box, Divider } from '@mui/material'
import { PageNavButton } from '../components/PageNavButton';

export default function SettingsView() {
    return (
        <Container maxWidth="2xl">
             <PageNavButton pageTitle='Mine Site Settings' />

            <Box alignItems='center' display='flex' component="span" sx={{ py: 2}}>
                <Box sx={{ flexGrow: 1 }}>
                    <Typography align='left' variant="h6" component="h2" sx={{ textTransform: 'uppercase'}}>
                        Manage Settings
                    </Typography>
                </Box>
            </Box>

            <Divider sx={{ mb: '40px' }} />

            <SettingsTable />
        </Container>
    )
}