export const PRODUCTION_URL = 'https://production.akumen-cloud.com';
export const STAGING_URL = 'https://staging.akumen-cloud.com';

/**
 * Scenario names that are associated with each data set in Akumen
 * 
 * @see The Model/Study/View config is in DataAPI.js
 */
export const DEVELOPMENT_SCENARIO = 'Jumbo';
export const BOGGING_SCENARIO = 'Bogging';
export const DRILLING_SCENARIO = 'Long Hole Drilling';
export const TRUCKING_SCENARIO = 'Trucking';
export const TRUCKING_RECONCILED_SCENARIO = 'Trucking - TonnesReconciled';

/**
 * Colours used to distinguish between different data sets
 */
export const SELECTION_COLOUR = '#673ab7'
export const DEVELOPMENT_COLOUR = '#83C775'
export const DRILLING_COLOUR = '#36347B'
export const BOGGING_COLOUR = '#FFDE17'
export const TRUCKING_COLOUR = '#8E191C'
export const OFF_TARGET_COLOUR = '#ef5350';

export const STATUS_BAR_COLOURS = {
    default: 'lightgrey',
    inflection: '#69c0ff',
    offTarget: '#ff6c6c',
}