import PropTypes from 'prop-types';
import { FormControl, Select, MenuItem } from '@mui/material'

export default function SettingSelect(props) {
    const { options } = props;

    return (
        <FormControl>
            <Select
                size="small"
                label=""
                style={{ fontSize: 14, backgroundColor: 'white' }}
                notched={false}
                {...props}
            >
                {
                    options.map(option => (
                        <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                    ))
                }
            </Select>
        </FormControl>
    )
}

SettingSelect.muiName = 'Select';

SettingSelect.propTypes = {
   options: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
   }))
}