import React from 'react';
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { addDays, endOfMonth, endOfQuarter, format, startOfMonth, startOfQuarter, startOfWeek, sub } from 'date-fns';
import { add } from 'date-fns/esm';

import { addStatusBarLegend, addStatusBarSeries } from '../utils/status-bar-utilities';

//AmChart in react class

import {
    SELECTION_COLOUR,
    DEVELOPMENT_COLOUR,
    DRILLING_COLOUR,
    BOGGING_COLOUR,
    TRUCKING_COLOUR,
} from '../constants';

// Define custom theme
class MyTheme extends am5.Theme {
    setupDefaultRules() {
      /* this.rule("Graphics", ["line", "series", "stroke"]).setAll({
        strokeWidth: 2,
        strokeDasharray: [10, 5]
      }); */

      this.rule("Label", ["axis"]).setAll({
        fontSize: 12,
        location: 1,
        multiLocation: 0 
      });

      this.rule("Cursor",["lineX"]).setAll( {
        stroke:  am5.color("#8F3985"),
        strokeWidth: 4,
        strokeOpacity: 0.2,
        strokeDasharray: []
    })
      
      //Set colours to use for series
      this.rule("ColorSet").set("colors", [
        am5.color(DEVELOPMENT_COLOUR),
        am5.color(DRILLING_COLOUR),
        am5.color(BOGGING_COLOUR),
        am5.color(TRUCKING_COLOUR),
        am5.color(0xbb9f06)
    ]);
    }
  }


class AmChart extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            TruckingSeries: null,
            DevelopmentSeries: null,
            BoggingSeries: null,
            DrillingSeries: null
        };
    }

    statusBarSeries = null;

    developmentForecast = null;
    boggingForecast = null;
    drillingForecast = null;
    truckingForecast = null;
    forecastRange = null;

    currentDate = null;
    selectedDate = null;
    selectionRange = null;
    actualDataEndDate = null; 

    statusBarSeries = null;

    chartRangeSet = false; //flag to control the chart initial zoom
    displayedAxes = [];
    targetSeries = [];

    showSelectedRange = () => {
        //hilight the area on the chart that corrasponds to
        //  selected date and spesified timeline
        
        //clear current selection
        if (this.selectionRange)
            this.xAxis.axisRanges.removeValue(this.selectionRange);
        
        let startDate = this.selectedDate;
        let endDate = addDays(this.selectedDate,1).getTime();
        switch ( this.timeline ) {
            case 'Weekly' :
                startDate = startOfWeek(this.selectedDate).getTime();
                endDate = addDays(startDate,7).getTime(); 
            break;
            case 'Monthly' :
                startDate = startOfMonth(this.selectedDate).getTime();
                endDate = endOfMonth(startDate).getTime(); 
            break;
            case 'Quarterly' :
                startDate = startOfQuarter(this.selectedDate).getTime();
                endDate = endOfQuarter(startDate).getTime();
            break;

            default:
        }

        this.selectionRange = this.xAxis.makeDataItem({
            value: startDate,
            endValue: endDate
        });
        
        this.xAxis.createAxisRange(this.selectionRange);

        this.selectionRange.get("grid").set("visible",false);
        this.selectionRange.get("axisFill").setAll({
            visible: true,
            fill: am5.color(SELECTION_COLOUR),
            fillOpacity: 0.4
        });
        
    }

    showForecastRange = () => {
        //hilight chart area that contains forecast data

        //remove existing range
        if (this.forecastRange) 
            this.xAxis.axisRanges.removeValue(this.forecastRange);

        //Assumption is that all the actual data ends on the same day
        //  ( or close to the same )
        if (this.actualDataEndDate) {
            
            let start = addDays(this.actualDataEndDate,1).getTime();
            let end =  addDays(start,90).getTime();  
            this.forecastRange = this.xAxis.makeDataItem({
                value: start,
                endValue: end
            });
            
            this.xAxis.createAxisRange(this.forecastRange);
    
            this.forecastRange.get("axisFill").setAll({
                visible: true,
                fillOpacity: 0.1,
                fill: am5.color("rgb(0,181,226)"),
            });
            this.forecastRange.get("label").setAll({
                text: "FORECAST",
                location: 0
            })

           
        }
    }

    addSeries = (name,colour, seriesData, hasOwnAxis = false) => {    
        var seriesYAxis = this.yAxis;
        if ( hasOwnAxis ) {
            //if hasOwnAxis then create a new axis for data
            seriesYAxis = this.chart.yAxes.push(
                am5xy.ValueAxis.new(this.root, {
                    //syncWithAxis: this.yAxis,
                    renderer: am5xy.AxisRendererY.new(this.root, {}),
                    
                })
            );
            seriesYAxis.gridContainer.hide();
            seriesYAxis.hide();
        }

        //add seriesData to chart 
        let series = this.chart.series.push(
            am5xy.ColumnSeries.new(this.root, {
                name: name,
                xAxis: this.xAxis,
                yAxis: seriesYAxis,
                valueYField: "value",
                valueXField: "date",
                openValueYField: "open",
                //valueYGrouped: "high",
                //openValueYGrouped: "low",
                stacked: false,
                groupDataDisabled: true,
                stroke: colour
            })
        );
        series.columns.template.setAll({
            fillOpacity: 0.7,
            strokeWidth: 2,
            cornerRadiusTL: 10,
            cornerRadiusTR: 10,
            cornerRadiusBL: 10,
            cornerRadiusBR: 10,
            stroke: colour,
            fill: colour
        });
        
        series.columns.template.setAll({
            width: am5.percent(80),
            tooltipY: 0
        });

        //Tooltip
        let tooltip = series.set("tooltip",
        am5.Tooltip.new(this.root, {
            getFillFromSprite: false,
            getStrokeFromSprite: true,
            autoTextColor: false
        }));
          
        tooltip.get("background").setAll({
            fill: am5.color(0xffffff),
            fillOpacity: 0.8
        });

        tooltip.label.setAll({
            text:"["+colour+"]●[/] {name} [bold]{value.formatNumber('#.0a')}[/]",
            fill: am5.color(0x000000)
        })

        tooltip.label.adapters.add("text",function(text,target){
            
            return text;
        })

        //Set fixed Axix range 
        //Doing this ourself as amCharts was not calculating correctly
        series.events.on("datavalidated", function(ev) {
            var series = ev.target;
            var yAxis = series.get("yAxis");
            var values = series.data.values;
            var max = Math.max(...values.map(v=>v.value));
            var min = Math.min(...values.map(v=>v.value).filter(Boolean));
            
            if ( max > 0 ) {
                yAxis.setAll({
                    min: yAxis.getPrivate("min"),
                    //min: min,
                    max: max
                });
            }
            
        });

        series.data.setAll(seriesData);
        //this.legend.data.push(series);
        return series;
    }

    addFilledSeries = (name, seriesData, seriesYAxis = this.yAxis) => {   
        //Adds a filled series showing range value 
        //lower - value - upper 

        //Returns {average: series, range: series}

        let line = this.chart.series.push(
            am5xy.LineSeries.new(this.root, {
                name: name + "-value",
                xAxis: this.xAxis,
                yAxis: seriesYAxis,
                valueYField: "value",
                valueXField: "date",
                stroke: '#555555',
                layer: 10,   
            })
        );
        line.strokes.template.setAll({
            strokeWidth: 1
        });

        line.data.setAll(seriesData);
        
        //add seriesData to chart 
        let fillSeries = this.chart.series.push(
            am5xy.LineSeries.new(this.root, {
                name: name + "-range",
                xAxis: this.xAxis,
                yAxis: seriesYAxis,
                valueYField: "upper",
                valueXField: "date",
                openValueYField: "lower",
                fill: am5.color(0x555555)    
            })
        );

        fillSeries.strokes.template.setAll({
            strokeWidth: 1
        });

        fillSeries.fills.template.setAll({
            fillOpacity: 0.1,
            visible: true
        });

        fillSeries.data.setAll(seriesData);
        fillSeries.show();

        //Link line (average) visiblity to fill (range)
        line.on("visible",function(visible,target) {
            if (!visible) 
                fillSeries.hide();
            else
                fillSeries.show();
        });

        return { 'average': line, 'range' :fillSeries};  
    }

    createStaticTooltip = (locX,locY) => {
        let staticDisplay = this.root.container.children.push(
            am5.Container.new(this.root,{
                x: locX,
                y: locY,
                width: 320,
                height: 100,
                layer: 100,
                background: am5.Rectangle.new(this.root, {
                    fill: am5.color(0xFFFFFF),
                    fillOpacity: 0.5
                })
            })
        );

        this.staticTooltipLabel = staticDisplay.children.push(
            am5.Label.new(this.root, {
                text: "Static",
                fontSize: 14          
            }))  
    }

    updateStaticLabel = (cursorPos) => {
        //Set the text of the staticTooltipLabel
        if (this.staticTooltipLabel) {
           
            let xPos = this.xAxis.toAxisPosition(cursorPos);

            let item = this.xAxis.getSeriesItem(this.state.DevelopmentSeries,xPos);
            if (item) {
                let text = 'Development: [bold]' + item.dataContext.value.toFixed(1) + '[/]';
                this.staticTooltipLabel.set("text",text);
            }

            //WIP - still to add other info is required.
            //  currenlty not using static tooltip
                
        }
    }

    showTargetSeries = (name, seriesData, seriesYAxis = this.yAxis) => {
        let series = this.chart.series.push(
            am5xy.LineSeries.new(this.root, {
                name: name,
                xAxis: this.xAxis,
                yAxis: seriesYAxis,
                valueYField: "target",
                valueXField: "date",
                stroke: am5.color("rgb(200, 0, 0)"),
                layer: 5,   
            })
        );
        series.strokes.template.setAll({
            strokeWidth: 1,
            strokeDasharray:[6,4]
        });

        series.events.on("datavalidated", function(ev) {
            var series = ev.target;
            var yAxis = series.get("yAxis");
            var values = series.data.values;
            var seriesMax = Math.max(...values.map(v=>v.target));
            var seriesMin = Math.min(...values.map(v=>v.target).filter(Boolean));
            
            var axisMax = yAxis.getPrivate("max");
            var axisMin = yAxis.getPrivate("min");
            
            if ( seriesMax > 0 ) {
                yAxis.setAll({
                    min: Math.min(seriesMin,axisMin),
                    max: Math.max(seriesMax,axisMax)
                });
            }
        });

        let tooltip = series.set("tooltip",
        am5.Tooltip.new(this.root, {
            getFillFromSprite: false,
            getStrokeFromSprite: false,
            autoTextColor: false
        }));
          
        tooltip.get("background").setAll({
            fill: am5.color(0xffffff),
            fillOpacity: 0.8
        });

        tooltip.label.setAll({
            text:"[#AA0000]●[/] {name} [bold]{target.formatNumber('#.0a')}[/]",
            fill: am5.color(0x000000)
        })

        series.data.setAll(seriesData);
        return series;
    }

    addStatusBar() {
        const [series, xAxis] = addStatusBarSeries(
            this.chart,
            this.root,
            this.yAxis,
            this.props.statusBarData
        );

        this.statusXAxis = xAxis;

        this.statusBarSeries = series;
        addStatusBarLegend(this.chart, this.root);
    }

    zoomToActualDataEnd() {
        //Zoom the chart to the date range
        //actualDataEnd - 1 year to actualDataEnd + 90 days
        if ( this.actualDataEndDate ) {        
            let zoomEnd = add( this.actualDataEndDate, {days: 90}); 
            let zoomStart = sub(this.actualDataEndDate, {years: 1}); 
            this.xAxis.zoomToDates(zoomStart,zoomEnd);
            this.statusXAxis.zoomToDates(zoomStart,zoomEnd);
        }
    }
    
    componentDidMount() {
        const root = am5.Root.new("chartdiv");
        this.root = root;

        //Define the chart
        root.setThemes([
            am5themes_Animated.new(root),
            MyTheme.new(root)
        ]);
      
        let chart = root.container.children.push( 
            am5xy.XYChart.new(root, {
                panX: true,
                panY: false,
                layout: root.verticalLayout
            })
        );
        this.chart = chart;
      
        // Create Y-axis
        let yAxis = chart.yAxes.push(
            am5xy.ValueAxis.new(root, {
                renderer: am5xy.AxisRendererY.new(root, {})
            })
        );
        this.yAxis = yAxis;
        yAxis.hide();
      
        // Create X-Axis
        let xAxis = chart.xAxes.push(
            am5xy.DateAxis.new(root, {
                baseInterval:{timeUnit: 'day',count: 1},
                renderer: am5xy.AxisRendererX.new(root, {}),
                groupData: false,
                tooltipDateFormat: "EEE dd.MM",

                //groupCount: 180       
            })
        );
        let xRenderer = xAxis.get("renderer");
        xRenderer.labels.template.setAll({
            minPosition: 0.05,
            maxPosition: 0.95
        });
        //TODO Show label at start of chart
        
        let tooltip = am5.Tooltip.new(this.root, {});
        tooltip.get("background").setAll({
            fill: am5.color(SELECTION_COLOUR)
        })
        
        xAxis.set("tooltip",tooltip);

        //Event -> date changed callback
        tooltip.events.on("click", (ev) => {           
            this.selectedDate = this.currentDate;

            if ( this.props.onDateSelected ) {
                this.props.onDateSelected(this.selectedDate);
            }
            
            this.showSelectedRange();
        });
        
        this.xAxis = xAxis;
        
        //xAxis.data.setAll(this.state.chartData);

        // Add legend (use unshift to add at the top) 
        let legend = chart.children.unshift(am5.Legend.new(root, {
            y: am5.percent(0),
            centerY: am5.percent(50),
            x: am5.percent(50),
            centerX: am5.percent(50)
        }));
        this.legend = legend;
        legend.data.setAll(chart.series.values);

        // Add cursor
        let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
            xAxis: xAxis
        }));
        
        cursor.lineY.setAll({
            visible: false
        })
        

        //Scrollbar
        let scrollbar = chart.set("scrollbarX",
            am5.Scrollbar.new(root,{
                orientation: "horizontal"
            })
        );
        //hide end grips
        scrollbar.startGrip.setAll({
            visible: false
        });
        scrollbar.endGrip.setAll({
            visible: false
        });

        //Track cursor position
        cursor.events.on("cursormoved", (ev) => {

            var x = ev.target.getPrivate("positionX"); 
            var dateAxis = ev.target.chart.xAxes.getIndex(0);
            var dateX = dateAxis.positionToDate(dateAxis.toAxisPosition(x));
            var dateOnly = new Date(dateX.getFullYear(), dateX.getMonth(), dateX.getDate());
            this.currentDate = dateOnly.getTime();

            //this.updateStaticLabel(x);
        });
        
        let series = this.addSeries("Development",DEVELOPMENT_COLOUR,this.props.developmentData,true);
        this.setState({DevelopmentSeries: series});
        
        this.developmentForecast = this.addFilledSeries(
            "Development Forecast",
            this.props.developmentForecast,
            series.get("yAxis")
        );
        
        series = this.addSeries("Long Hole Drilling",DRILLING_COLOUR,this.props.drillingData,true);
        this.setState({DrillingSeries: series});
        this.drillingForecast = this.addFilledSeries(
            "Drilling Forecast",
            this.props.drillingForecast,
            series.get("yAxis")
        );
        
        series = this.addSeries("Bogging",BOGGING_COLOUR,this.props.boggingData,true);
        this.setState({BoggingSeries: series});
       
        this.boggingForecast = this.addFilledSeries(
            "Bogging Forecast",
            this.props.boggingForecast,
            series.get("yAxis")
        );
                
        series = this.addSeries("Trucking",TRUCKING_COLOUR,this.props.truckingData);
        this.setState({TruckingSeries: series});
        
        this.truckingForecast = this.addFilledSeries(
            "Trucking Forecast",
            this.props.truckingForecast,
            series.get("yAxis")
        );
       
        this.addStatusBar();

        this.chart.plotContainer.events.on('click',(ev)=>{
            this.selectedDate = this.currentDate;

            if ( this.props.onDateSelected ) {
                this.props.onDateSelected(this.selectedDate);
            }
            
            this.showSelectedRange();
        })

        this.addAuxLegend();
        //this.createStaticTooltip(100,100);
    }

    componentWillUnmount() {
        if ( this.root ){
            this.root.dispose();
        }
    }

    showSeriesYAxis() {
        //Shows the yAxis of a series if it is the only displayed series
        // otherwise hides the yAxis of all series
        // Also shows targat data when a single activity is selected
        let displayed = [];
        if ( this.props.showDevelopment && this.state.DevelopmentSeries )
            displayed.push({name : 'development', series: this.state.DevelopmentSeries});   
        if ( this.props.showBogging && this.state.BoggingSeries )
            displayed.push({name: 'bogging', series: this.state.BoggingSeries});
        if ( this.props.showDrilling && this.state.DrillingSeries )
            displayed.push({name: 'drilling',series: this.state.DrillingSeries});
        if ( this.props.showTrucking && this.state.TruckingSeries)
            displayed.push({name: 'trucking', series: this.state.TruckingSeries});

        this.targetSeries.forEach((series) => {
            this.chart.series.removeIndex(
                this.chart.series.indexOf(series)
            ).dispose();
        })
        this.targetSeries.length = 0;

        if ( displayed.length == 1 ) {
            let yAxis = displayed[0].series.get("yAxis");
            yAxis.show();
            this.displayedAxes.push(yAxis);
            let seriesData = this.props.targetData[displayed[0].name];
            if (seriesData) {
                var series = this.showTargetSeries(displayed[0].name + "-target", seriesData, yAxis)
                this.targetSeries.push(series);
            }

            this.auxLegend.data.setAll([
                {
                    name: 'Forecast Mean',
                    color: am5.color('#000000'),
                }
            ]);

            this.auxLegend.data.push(series);
        }
        else {
            this.displayedAxes.forEach((axis) => {
                axis.hide();
            })
            this.displayedAxes.length = 0;
            this.auxLegend.data.setAll([
                {
                    name: 'Forecast Mean',
                    color: am5.color('#000000')
                }
            ]);
        }

    }

    addAuxLegend() {
        let auxLegend = this.chart.children.unshift(am5.Legend.new(this.root, {
            nameField: 'name',
            fillField: 'color',
            strokeField: 'color',
            centerX: am5.percent(0),
            x: am5.percent(0)
        }));

        auxLegend.markers.template.setAll({
            width: 16,
            height: 1
        });

        auxLegend.labels.template.setAll({
            fontSize: 12,
        });

        this.auxLegend = auxLegend;
    }

    componentDidUpdate() {
        if ( this.state.TruckingSeries ){
            let series = this.state.TruckingSeries;
            series.data.setAll(this.props.truckingData);
            if (this.state.TruckingSeries.data.length > 1) {
                let lastindex = this.state.TruckingSeries.data.length - 1;
                let lastRec = this.state.TruckingSeries.data.values[lastindex];
                this.actualDataEndDate = lastRec.date;  
            }
        }

        if ( this.state.DevelopmentSeries ) {
           
            this.state.DevelopmentSeries.data.setAll(this.props.developmentData);
        }

        if ( this.state.BoggingSeries ) {
            
            this.state.BoggingSeries.data.setAll(this.props.boggingData);
            if (this.state.BoggingSeries.data.length > 1) {
                let lastindex = this.state.BoggingSeries.data.length - 1;
                let lastRec = this.state.BoggingSeries.data.values[lastindex];
                this.actualDataEndDate = lastRec.date;  
            }
        }

        if ( this.state.DrillingSeries ){
            let series = this.state.DrillingSeries;
            //series.data.setAll(this.state.TruckingData)
            series.data.setAll(this.props.drillingData)
        }

        if ( this.developmentForecast ) {
            this.developmentForecast.average.data.setAll(this.props.developmentForecast);
            this.developmentForecast.range.data.setAll(this.props.developmentForecast);
        }
        if (this.drillingForecast ) {
            this.drillingForecast.average.data.setAll(this.props.drillingForecast)
            this.drillingForecast.range.data.setAll(this.props.drillingForecast)
        }
        if (this.boggingForecast ) {
            this.boggingForecast.average.data.setAll(this.props.boggingForecast)
            this.boggingForecast.range.data.setAll(this.props.boggingForecast)
        }
        if ( this.truckingForecast ) {
            this.truckingForecast.average.data.setAll(this.props.truckingForecast)
            this.truckingForecast.range.data.setAll(this.props.truckingForecast)

            this.showForecastRange(); 
            //Zoom chart - NB Delay is a work around to allow the chart to draw before zoom
            if ( !this.chartRangeSet ) {
                setTimeout(() => {
                    console.log("zoom");
                    this.zoomToActualDataEnd();
                    this.chartRangeSet = true;
                },500);
            }

        }

        if (this.statusBarSeries) {
            this.statusBarSeries.data.setAll(this.props.statusBarData);
        }

        let xAxes = [this.xAxis, this.statusXAxis];

        //Set Grouping/ Zoom based on timeline selected
        if ( this.timeline !== this.props.timeline ) {
            switch( this.props.timeline ) {
            case 'Daily':
                //this.zoomToActualDataEnd();    
                
            break;
            case 'Weekly':
                // xAxes.forEach(xAxis => {
                //     xAxis.zoom(0,0.2);
                //     xAxis.set("baseInterval",{timeUnit: "week", count: 1})
                //     xAxis.set("gridIntervals",{timeUnit: "week", count: 1})
                // });
            break;
            case 'Monthly':
                // xAxes.forEach(xAxis => {
                //     xAxis.set("baseInterval",{timeUnit: "month", count: 1})
                //     xAxis.zoom(0,0.2);
                // });
            break;
            case 'Quarterly':
                // xAxes.forEach(xAxis => {
                //     xAxis.zoom(0,1);
                // });
            break;
            default:
                
            }

            this.timeline = this.props.timeline;

            if ( this.selectedDate ) {
                this.showSelectedRange()
            }
        }

        //Set series visablity
        try {
            if (this.props.showDevelopment) {
                this.state.DevelopmentSeries.show();
                this.developmentForecast.average.show();            
                //this.developmentForecast.range.show();
            } else {
                this.state.DevelopmentSeries.hide();
                this.developmentForecast.average.hide();
                //this.developmentForecast.range.hide();
            }

            if (this.props.showDrilling) {
                this.state.DrillingSeries.show();
                this.drillingForecast.average.show();
            } else {
                this.state.DrillingSeries.hide();
                this.drillingForecast.average.hide();
            }

            if ( this.props.showBogging ) {
                this.state.BoggingSeries.show();
                this.boggingForecast.average.show();
            } else {
                this.state.BoggingSeries.hide();
                this.boggingForecast.average.hide();
            }

            if (this.props.showTrucking) {
                this.state.TruckingSeries.show();
                this.truckingForecast.average.show(); 
            } else {
                this.state.TruckingSeries.hide();
                this.truckingForecast.average.hide();
            }
            
        } catch (err) {
            console.log(err)
        }

        this.showSeriesYAxis();
    }

    render() {
        return (
            <>
                <div id="chartdiv" style={{ width: "100%", height: "525px" }}></div>
            </>
          );

    }
}

export default AmChart;