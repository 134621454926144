import { Container, TextField, FormGroup, Button, Box, Backdrop, CircularProgress, Alert, Typography, CssBaseline, AppBar, Toolbar, Avatar, Stack, Link } from '@mui/material';
import React, { useEffect, useState } from 'react';
import './Login.css';
import axios from 'axios';
import store from '../store/Store.js';
import { useNavigate } from 'react-router-dom';
import LoginOutlined from '@mui/icons-material/LoginOutlined';
import IdobaLogo from '../assets/idoba_logo.png';
import SumitomoLogo from '../assets/sumitomo_corporation_logo.png';

function Copyright(props) {
    return (
    <Stack justifyContent="flex-start" alignItems="center" spacing={1}>           
        <a href="https://idoba.com/" target="_blank" rel="noopener">          
            <Box component="img" src={IdobaLogo} sx={{height:'20px',width:'77px'}}/>
        </a>
        <a href="https://www.sumitomocorp.com/en/asia-oceania" target="_blank" rel="noopener">
            <Box component="img" src={SumitomoLogo} sx={{height:'20px',width:'201px'}}/>
        </a>                
                    
      <Typography variant="body2" color="text.secondary" align="center" {...props}>
        {'Copyright © '}
      <Link color="inherit" href="https://idoba.com/" target="_blank" rel="noopener">
          idoba
       </Link>
       {' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography> 
      
      </Stack>
    );
  }

export default function Login() {
    useEffect(() => {
        // Accessing the login screen should clear the auth token
        let state = store.getState();
        store.dispatch({ type: 'security/setJwt', payload: { jwt: '', local_storage_key: state.common.local_storage_key }})
    }, []);

    const [ username, setUsername] = useState('');
    const [ password, setPassword] = useState('');
    const [ errors, setErrors] = useState('');
    const [ loading, setLoading] = useState(false);
    const [ hasUsernameError, setHasUsernameError] = useState(false);
    const [ hasPasswordError, setHasPasswordError] = useState(false);
    const navigate = useNavigate();

    const login = () => {
        if (!username || !password) {
            setErrors('Username and password are required fields');
            setHasUsernameError(true);
            setHasPasswordError(true);
            return;
        }

        setLoading(true);
        setErrors('');
        setHasUsernameError(false);
        setHasPasswordError(false);

        let state = store.getState();
        let bodyFormData = new FormData();
        bodyFormData.append('username', username);
        bodyFormData.append('password', password);

        axios.post(`${state.common.baseUrl}/api/v1/securityuser/login`, { username: username, password: password })
            .then(
                response => {
                    try {
                        setLoading(false);
                        setErrors('');
                        store.dispatch({ type: 'security/setJwt', payload: { jwt: response.data.token, local_storage_key: state.common.local_storage_key }})
                        navigate("/overview");
                    }
                    catch (err) {
                        setErrors(err);
                        console.error(err);
                    }
                }
            )
            .catch(error => {
                setLoading(false);
                store.dispatch({ type: 'security/setJwt', payload: { jwt: '', local_storage_key: state.common.local_storage_key }})
                if (error.message && error.message === 'Network Error') {
                    setErrors('Akumen is not available');
                    setHasUsernameError(true);
                    setHasPasswordError(true);
                    return;
                }
                if (error.response.status === 401) {
                    setErrors('Incorrect username or password');
                    setHasUsernameError(true);
                    setHasPasswordError(true);
                    return;
                }
                if (error.response && error.response.data && error.response.data.detail) {
                    setErrors(error.response.data.detail);
                    setHasUsernameError(true);
                    setHasPasswordError(true);
                    return;
                }
            });
    }

    return (

        <>
        <CssBaseline />
        
        <AppBar position="relative" style={{ background: '#333333', boxShadow: "none" }}>
            <Toolbar variant="dense" align= "center">
                <Typography color="#fff">
                    Mine Performance Navigator
                </Typography>
            </Toolbar>
        </AppBar>
        <main>
            <div>
                <Container maxWidth="sm">
                <Box
                    sx={{
                        marginTop: 10,
                        marginBottom: 5,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                
                    <Backdrop open={loading}>
                        <CircularProgress></CircularProgress>
                    </Backdrop>
                
                    <Avatar sx={{ m: 1, bgcolor: '#000' }}>
                        <LoginOutlined />
                    </Avatar>
                    
                    <Typography component="h1" variant="h5">
                        Sample Mine Site
                    </Typography>
                    
                </Box>
                
                <Box sx={{ mt: 3, mb: 3, width: 1 }} display="grid" alignItems="center" >
                    { hasUsernameError || hasPasswordError ? <Alert severity='error'>{errors}</Alert> : null }
                </Box>

                    <FormGroup sx={{mb:10}}>
                        <TextField label="Enter Username" value={ username } onChange={e => setUsername(e.target.value)} sx={{ mb: 2 }} error={hasUsernameError}>
                        </TextField>
                        <TextField label="Enter Password" value={ password } onChange={e => setPassword(e.target.value)} error={hasPasswordError}
                            type="password">
                        </TextField>
                        
                    <Box sx={{ mt: 5 }} display="flex" justifyContent="center" alignItems="center">
                        <Button variant="contained" color="secondary" disableElevation onClick={() => login() }>Sign in</Button>
                    </Box>
                    
                    </FormGroup>
                                
                    <Copyright sx={{ mt: 8, mb: 4 }} />
                </Container>
            </div>
        </main>
        </>
    )
}