import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link } from 'react-router-dom';

export function PageNavButton (props) {

    return (        
        <Box display='flex' component="span" sx={{ paddingTop: 2, paddingBottom: 2}}>
            <Button component={Link} to={props.prevPageRoute} startIcon={<ArrowBackIcon />}>{props.prevPageTitle}</Button>
            <Typography align='left' variant="h6" component="h1" sx={{ textTransform: 'uppercase' }} >
                     / {props.pageTitle}
            </Typography>
        </Box>
    )
}

PageNavButton.defaultProps = {
    prevPageTitle: 'Mine Overview',
    prevPageRoute: '/overview'
}