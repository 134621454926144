export default function formatActivityDetailsData(data) {
    return data.map(rec => {
        let data = {
            date: new Date(rec.RecDate).getTime(),
            actual: parseFloat(rec.Actual),
            activity: rec.scenarioname,
            crew : parseFloat(rec.Crew),
            machinery_count: parseFloat(rec['Machine Count']),
            machinery_type: '',
            prod_hours: 8,
            target: parseFloat(rec.Target),
            //hasInflection: Math.random() <= 0.005, // random chance of inflection
            hasInflection: (rec.changepoint_count > 0)
        }

        // todo - uncomment once real data comes through
        data.offTarget = data.actual < data.target;

        // using fake data to render a better looking status bar
        //data.offTarget = Math.random() <= 0.05;

        return data;
    });
}
