import { OutlinedInput } from '@mui/material'

export default function SettingInput(props) {
    return (
        <OutlinedInput
            size="small"
            variant="outlined"
            label=""
            notched={false}
            color="secondary"
            style={{ fontSize: 14, backgroundColor: 'white' }}
            sx={{ borderWidth: 1 }}
            {...props}
        />
    )
}

SettingInput.muiName = 'OutlinedInput';