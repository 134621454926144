import {
    DEVELOPMENT_SCENARIO,
    BOGGING_SCENARIO,
    DRILLING_SCENARIO,
    TRUCKING_SCENARIO,
    DEVELOPMENT_COLOUR,
    DRILLING_COLOUR,
    BOGGING_COLOUR,
    TRUCKING_COLOUR,
} from '../constants';

import React, { useEffect, useCallback } from 'react';

import { Box, Container, FormControl, InputLabel, MenuItem, Select, Typography, Stack, Divider, CircularProgress, Tooltip } from '@mui/material';

import AmChart from './amChart';
import { PageNavButton } from '../components/PageNavButton';
import Button from '@mui/material/Button';
import CircleIcon from '@mui/icons-material/Circle';
import getDailyData, {getDailyActivityDetails, getForecastData} from '../components/DataAPI';
import { getAggActivityDetails } from '../components/DataAPI';
import ActivityDetailsView from '../components/ActivityDetailsView';

import formatActivityDetailsData from '../utils/format-activity-details-data';

export default function ProcessStabilityView() {

    const [timeline, setTimeline] = React.useState('Daily');
    const [lastLoaded, setLastLoaded] = React.useState(); //used to rerender on data load
    const [isLoading, setIsLoading] = React.useState(true); //Flag to data is being loaded
    const [developmentVisible, setDevelopmentVisible] = React.useState(true);
    const [drillingVisible, setDrillingVisible] = React.useState(true);
    const [boggingVisible, setBoggingVisible] = React.useState(true);
    const [truckingVisible, setTruckingVisible] = React.useState(true);

    //History / actual data 
    const [developmentData, setDevelopmentData] = React.useState([]);  
    const [boggingData, setBoggingData] = React.useState([]);  
    const [truckingData, setTruckingData] = React.useState([]);
    const [drillingData, setDrillingData] = React.useState([]);

    //Activity details data
    const [developmentDetails, setDevelopmentDetails] = React.useState([]);
    const [drillingDetails, setDrillingDetails] = React.useState([]);
    const [boggingDetails, setBoggingDetails] = React.useState([]);
    const [truckingDetails, setTruckingDetails] = React.useState([]);

    //Forcast
    const [truckingForecast, setTruckingForecast] = React.useState([]);
    const developmentForecast = React.useRef([]);
    const boggingForecast = React.useRef([]);
    const drillingForecast = React.useRef([]);

    // Status bar data
    const [statusBarData, setStatusBarData] = React.useState([]);

    //User selected date
    const [selectedDate, setSelectedDate] = React.useState();

    //aggrigated data sets
    const dailyDevelopmentDetails = React.useRef([]);
    const weeklyDevelopmentDetails = React.useRef([]);
    const monthlyDevelopmentDetails = React.useRef([]);
    const quarterlyDevelopmentDetails = React.useRef([]);

    const dailyBoggingDetails = React.useRef([]);
    const weeklyBoggingDetails = React.useRef([]);
    const monthlyBoggingDetails = React.useRef([]);
    const quarterlyBoggingDetails = React.useRef([]);

    const dailyDrillingDetails = React.useRef([]);
    const weeklyDrillingDetails = React.useRef([]);
    const monthlyDrillingDetails = React.useRef([]);
    const quarterlyDrillingDetails = React.useRef([]);

    const dailyTruckingDetails = React.useRef([]);
    const weeklyTruckingDetails = React.useRef([]);
    const monthlyTruckingDetails = React.useRef([]);
    const quarterlyTruckingDetails = React.useRef([]);

    const activitysRef = React.useRef(null);  //Referance for use with scrollInToView()

    
    const fetchActivityDetails = () => {
        let scenarios = [
            DEVELOPMENT_SCENARIO,
            BOGGING_SCENARIO,
            TRUCKING_SCENARIO,
            DRILLING_SCENARIO,
        ];

        Promise.all(scenarios.map(scenario => getDailyActivityDetails(scenario)))
            .then(([development, bogging, trucking, drilling]) => {
                development = formatActivityDetailsData(development.data.DataValues);
                bogging = formatActivityDetailsData(bogging.data.DataValues);
                trucking = formatActivityDetailsData(trucking.data.DataValues);
                drilling = formatActivityDetailsData(drilling.data.DataValues);

                setDevelopmentDetails(development);
                dailyDevelopmentDetails.current = development;

                setBoggingDetails(bogging);
                dailyBoggingDetails.current = bogging;

                setTruckingDetails(trucking);
                dailyTruckingDetails.current = trucking;

                setDrillingDetails(drilling);
                dailyDrillingDetails.current = drilling;

                aggregateStatusBarData();
            })
            .catch(errors => {
                console.log(errors);
                console.log("Error fetching data");
            });
    }

    const aggregateStatusBarData = useCallback(() => {
        let dataPoints = [];

        developmentVisible && dataPoints.push(dailyDevelopmentDetails.current);
        drillingVisible && dataPoints.push(dailyDrillingDetails.current);
        boggingVisible && dataPoints.push(dailyBoggingDetails.current);
        truckingVisible && dataPoints.push(dailyTruckingDetails.current);

        let statusBarData = dataPoints.length === 0
            ? []
            : dataPoints[0].map(data => {
                return {
                    date: data.date,
                    value: 750,
                    type: 'default',
                }
            });

        setStatusBarData(
            statusBarData.map((data, index) => {
                const anyDataPointHasInflection = dataPoints.some(dataPoint => dataPoint[index] !== undefined && dataPoint[index].hasInflection);
                const anyDataPointHasOffTarget = dataPoints.some(dataPoint => dataPoint[index] !== undefined && dataPoint[index].offTarget);

                if (anyDataPointHasOffTarget) {
                    data.type = 'offTarget';
                }

                if (anyDataPointHasInflection) {
                    data.type = 'inflection';
                }

                return data;
            })
        );
    }, [developmentVisible, drillingVisible, boggingVisible, truckingVisible]);

    useEffect(() => {
        aggregateStatusBarData();
    }, [developmentVisible, drillingVisible, boggingVisible, truckingVisible, aggregateStatusBarData]);

    useEffect(() => {
        //Fire off data requests
        fetchActivityDetails();

        //Weekly
        getAggActivityDetails(DEVELOPMENT_SCENARIO,'Weekly',(data) => {
            weeklyDevelopmentDetails.current = data;
        });

        getAggActivityDetails(BOGGING_SCENARIO,'Weekly', (data) => {
            weeklyBoggingDetails.current = data;
        });

        getAggActivityDetails(DRILLING_SCENARIO,'Weekly',(data) => {
            weeklyDrillingDetails.current = data;
        });

        getAggActivityDetails(TRUCKING_SCENARIO,'Weekly',(data) => {
            weeklyTruckingDetails.current = data;
        });

        //Monthly
        getAggActivityDetails(DEVELOPMENT_SCENARIO,'Monthly',(data) => {
            monthlyDevelopmentDetails.current = data;
        })
        getAggActivityDetails(BOGGING_SCENARIO,'Monthly', (data) => {
            monthlyBoggingDetails.current = data;
        });
        getAggActivityDetails(DRILLING_SCENARIO,'Monthly',(data) => {
            monthlyDrillingDetails.current = data;
        });
        getAggActivityDetails(TRUCKING_SCENARIO,'Monthly',(data) => {
            monthlyTruckingDetails.current = data;
        });

        //Quarterly
        getAggActivityDetails(DEVELOPMENT_SCENARIO,'Quarterly',(data) => {
            quarterlyDevelopmentDetails.current = data;
        })
        getAggActivityDetails(BOGGING_SCENARIO,'Quarterly', (data) => {
            quarterlyBoggingDetails.current = data;
        });
        getAggActivityDetails(DRILLING_SCENARIO,'Quarterly',(data) => {
            quarterlyDrillingDetails.current = data;
        });
        getAggActivityDetails(TRUCKING_SCENARIO,'Quarterly',(data) => {
            quarterlyTruckingDetails.current = data;
        });

        

        //History and Forecast
        //Development
        getDailyData(DEVELOPMENT_SCENARIO,(newData) => {
            setDevelopmentData(newData);  
        })
        getForecastData(DEVELOPMENT_SCENARIO,(data) => {
            developmentForecast.current = data;
            
        })

        //Bogging
        getDailyData(BOGGING_SCENARIO,(newData) => {
            setBoggingData(newData); 
        })
        getForecastData(BOGGING_SCENARIO,(data) => {
            boggingForecast.current = data;
            
        })

        //Trucking
        getDailyData(TRUCKING_SCENARIO,(newData) => {
            setTruckingData(newData);   
        });
        getForecastData(TRUCKING_SCENARIO,(data) => {
            setTruckingForecast(data);   
        }) 

        //Drilling
        getDailyData(DRILLING_SCENARIO,(newData) => {
            setDrillingData(newData);  
        })
        getForecastData(DRILLING_SCENARIO,(data) => {
            drillingForecast.current = data;
        })  

        //TODO monitor loading state of each data set
        // and display loaded feedback
        // react-promise-tracker + load spinner
        setTimeout(()=>{
            setIsLoading(false);    
        },2000)

    },[]);

    const handleTimelineChange = (event) => {
        setTimeline(event.target.value);
        //TODO set detail data set ? to required aggrigation (daily/weekly/monthy)
        switch( event.target.value ) {
            case 'Daily':
                setDevelopmentDetails(dailyDevelopmentDetails.current);
                setBoggingDetails(dailyBoggingDetails.current);
                setDrillingDetails(dailyDrillingDetails.current);
                setTruckingDetails(dailyTruckingDetails.current);
            break;
            case 'Weekly':
                setDevelopmentDetails(weeklyDevelopmentDetails.current);
                setBoggingDetails(weeklyBoggingDetails.current);
                setDrillingDetails(weeklyDrillingDetails.current);
                setTruckingDetails(weeklyTruckingDetails.current);
            break;
            case 'Monthly' :
                setDevelopmentDetails(monthlyDevelopmentDetails.current);
                setBoggingDetails(monthlyBoggingDetails.current);
                setDrillingDetails(monthlyDrillingDetails.current);
                setTruckingDetails(monthlyTruckingDetails.current);
            break;
            case 'Quarterly' :
                setDevelopmentDetails(quarterlyDevelopmentDetails.current);
                setBoggingDetails(quarterlyBoggingDetails.current);
                setDrillingDetails(quarterlyDrillingDetails.current);
                setTruckingDetails(quarterlyTruckingDetails.current);
            default:
                //Daily
        }     

    };
    
    const scrollToActivityRef = () => window.scrollTo({top: activitysRef.current.offsetTop - 75,behavior :'smooth'});

    const anyActivityIsVisible = () => {
        return selectedDate && (developmentVisible || drillingVisible || boggingVisible || truckingVisible);
    }
    return (
        <Container maxWidth="2xl">
            <PageNavButton pageTitle='Process Stability' />
            <Box alignItems='center' display='flex' component="span" sx={{ p: 2}}>
                <Box sx={{ flexGrow: 1 }}>
                    <Typography align='left' variant="h6" component="h2" sx={{ textTransform: 'uppercase'}}>
                        Explore Process Stability Across Activities
                    </Typography>
                </Box>
                
                <Box sx={{ alignItems: 'right' }}>
                    <Typography variant="subtitle2" component="h6" sx={{ mr: '12px' }}>
                        View Aggregated Metrics by:
                    </Typography>
                </Box>

                <Box sx={{ alignItems: 'right', minWidth: '150px' }}>
                <FormControl fullWidth>
                    <InputLabel variant="standard" id="select-timeline">&nbsp;</InputLabel>
                    <Select
                        labelId="select-timeline"
                        id="project-timeline"
                        value={timeline}
                        size="small"
                        onChange={handleTimelineChange}
                    >
                    <MenuItem value={'Daily'}>Day</MenuItem>
                    <MenuItem value={'Weekly'}>Week</MenuItem>
                    <MenuItem value={'Monthly'}>Month</MenuItem>
                    <MenuItem value={'Quarterly'}>Quarter</MenuItem>
                    </Select>
                </FormControl>              
                </Box>
            </Box>

            <Divider sx={{ mb: '40px' }} />
            
            <Stack direction="row" justifyContent="center" spacing={2} alignItems="center" sx={{mb:2}}>   
                <Button 
                    variant="outlined"
                    color={developmentVisible ? "secondary" : "primary"} 
                    sx={{minWidth:200}}
                    onClick={() => {
                        setDevelopmentVisible(!developmentVisible);  
                    }}
                    startIcon={<CircleIcon 
                        style={developmentVisible ? {color: DEVELOPMENT_COLOUR} : {color: 'lightgrey'}} 
                        size="medium" />}>
                    Development (m)
                </Button>
                <Button 
                    variant="outlined"
                    color={drillingVisible ? "secondary" : "primary"}
                    sx={{minWidth:200}}
                    onClick={() => {
                        setDrillingVisible(!drillingVisible); 
                    }}
                    startIcon={<CircleIcon 
                        style={drillingVisible ? {color: DRILLING_COLOUR} : {color: 'lightgrey'}} 
                        size="medium" />}>
                    Long Hole Drilling (m)
                </Button>
                <Button 
                    variant="outlined"
                    color={boggingVisible ? "secondary" : "primary"}
                    sx={{minWidth:200}}
                    onClick={() => {
                        setBoggingVisible(!boggingVisible); 
                    }}
                    startIcon={<CircleIcon 
                        style={boggingVisible ? {color: BOGGING_COLOUR} : {color: 'lightgrey'}} 
                        size="medium" />}>
                    Bogging (t)
                </Button>
                <Button 
                    variant="outlined"
                    color={truckingVisible ? "secondary" : "primary"} 
                    sx={{minWidth:200}}
                    onClick={() => {                      
                        setTruckingVisible(!truckingVisible); 
                    }}
                    startIcon={<CircleIcon 
                        style={truckingVisible ? {color: TRUCKING_COLOUR} : {color: 'lightgrey'}} 
                        size="medium" />}>
                    Trucking (tkm)
                </Button>
            </Stack>
            
          <Box>  
            {isLoading 
            ? 
              <Box alignItems='center' 
                   justifyContent='center' 
                   display='flex' 
                   component="span" 
                   sx={{ height:'400px', p: 2}}>
                 <Stack alignItems='center'>
                    <CircularProgress color="secondary"/>
                    <Typography>Loading data ...</Typography>
                 </Stack>    
              </Box> 
            :
              <AmChart 
                dataLoaded={lastLoaded}
                timeline={timeline}
                developmentData={developmentData}
                developmentForecast={developmentForecast.current}
                boggingData={boggingData}
                boggingForecast={boggingForecast.current}
                drillingData={drillingData}
                drillingForecast={drillingForecast.current}
                truckingData={truckingData}
                truckingForecast={truckingForecast}
                statusBarData={statusBarData}
                showDevelopment={developmentVisible}
                showDrilling={drillingVisible}
                showBogging={boggingVisible}
                showTrucking={truckingVisible}
                targetData={{development: dailyDevelopmentDetails.current,
                             bogging: dailyBoggingDetails.current,
                             drilling: dailyDrillingDetails.current,
                             trucking: dailyTruckingDetails.current}}
                onDateSelected={(d)=>{
                    setSelectedDate(d);
                    scrollToActivityRef();
                }}/>
            }   
          </Box>
        
          <>{ anyActivityIsVisible() ? <>
          <Box ref={activitysRef} alignItems='center' display='flex' component="span" sx={{ p: 2, mt: '40px' }}>
                <Box sx={{ flexGrow: 1 }}>
                    <Typography align='left' variant="h6" component="h2" sx={{ textTransform: 'uppercase'}}>
                        Process Stability Activity Details 
                    </Typography>
                </Box>
                
                <Box sx={{ alignItems: 'right' }}>
                    <Typography variant="subtitle2" component="h6" sx={{ mr: '12px' }}>
                        View Aggregated Metrics by:
                    </Typography>
                </Box>

                <Box sx={{ alignItems: 'right', minWidth: '150px' }}>
                <FormControl fullWidth>
                    <InputLabel variant="standard" id="select-timeline">&nbsp;</InputLabel>
                    <Select
                        labelId="select-timeline"
                        id="project-timeline"
                        value={timeline}
                        size="small"
                        onChange={handleTimelineChange}
                    >
                    <MenuItem value={'Daily'}>Day</MenuItem>
                    <MenuItem value={'Weekly'}>Week</MenuItem>
                    <MenuItem value={'Monthly'}>Month</MenuItem>
                    <MenuItem value={'Quarterly'}>Quarter</MenuItem>
                    </Select>
                </FormControl>              
                </Box>
            </Box>

            <Divider sx={{ mb: '40px' }} />
            </>
            : <></> }
          </> 

          <>{developmentVisible ?
          <Box sx={{ mt: '32px' }}>
              <ActivityDetailsView 
                title="Development"
                unitDesc="Meter Advance"
                machineType="jumbos"
                seriesColour={DEVELOPMENT_COLOUR}
                timeline={timeline}
                details={developmentDetails}
                selectedDate={selectedDate}/>
          </Box> : <></> }
          </>
          <>{drillingVisible ?
          <Box sx={{ mt: '32px' }}>
              <ActivityDetailsView 
                title="Long Hole Drilling"
                unitDesc="Meter Advance"
                machineType="drills"
                seriesColour={DRILLING_COLOUR}
                timeline={timeline}
                details={drillingDetails}
                selectedDate={selectedDate}/>
          </Box> : <></> }
          </>
          <>{boggingVisible ?
          <Box sx={{ mt: '32px' }}>
              <ActivityDetailsView 
                title="Bogging"
                unitDesc="Tonnes"
                machineType="boggers"
                seriesColour={BOGGING_COLOUR}
                timeline={timeline}
                details={boggingDetails}
                selectedDate={selectedDate}/>
          </Box> : <></> }
          </>
          <>{truckingVisible ?
          <Box sx={{ mt: '32px' }}>
              <ActivityDetailsView 
                title="Trucking"
                unitDesc="TKM"
                machineType="trucks"
                seriesColour={TRUCKING_COLOUR}
                timeline={timeline}
                details={truckingDetails}
                selectedDate={selectedDate}/>
          </Box> : <></> }
          </>
        </Container>    
    )
}
