import { format } from 'date-fns'
import { useState, useEffect, useRef } from 'react';

import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Snackbar } from '@mui/material'
import ActivityDataSourceRow from './ActivityDataSourceRow';
import { getActiveFilenames, getStudyRunStatus } from './DataAPI';

export default function ActivityDataSourceTable(props) {
    const { isExecuting } = props;
    
    const [rows, setRows] = useState(defaultRows);
    const [activeFiles, setActiveFiles] = useState([]);

    const [monitorExecution, setMonitorExecution] = useState(isExecuting);
    
    const statusMonitor = useRef(null);

    const toggleActive = row => {
        getStudyRunStatus(setActivityStatus);
    }

    const monitorActive = row => {
        setMonitorExecution(true);  
        
        
        //setTimeout(()=>{
        //    console.log("Set monitorExecution = false");
        //    setMonitorExecution(false);    
        //},30000)
    }

    const setActivityStatus = (activityStatus) => {
        let updatedRows = [...rows];

        let monitorStatus = false;
        let statesToMonitor = ['Queued','Running','SavingOutputs']
        updatedRows.forEach((row) => {
            let activity = activityStatus.find((s)=>s.name == row.scenarioName);
            if ( activity ) {
                row.runStatus = (activity.status == 'Complete');
                row.statusTxt = activity.status;

                if (statesToMonitor.includes(activity.status)) {
                    monitorStatus = true;    
                }
            }
        })

        setRows(updatedRows);
        console.log('Activity status updated')
        setMonitorExecution(monitorStatus);
        console.log('Monitor = ',monitorStatus);
    }

    //Retrive document records and active files
    useEffect(() => {
        getActiveFilenames(setActiveFiles);
        getStudyRunStatus(setActivityStatus);

        return () => {
            if (statusMonitor.current)
                clearInterval(statusMonitor.current);
        }
    },[]);

    useEffect(() => {
        console.log('ActiveFiles set')

        let updatedRows = [...rows];
        updatedRows.forEach((row) => {
            row.filename = activeFiles[row.activity];
        })

        setRows(updatedRows);

    },[activeFiles]);

    useEffect(() => {
        if (statusMonitor.current)
            clearInterval(statusMonitor.current);

        if ( monitorExecution || isExecuting ) {
            statusMonitor.current = setInterval(()=>{
                console.log("toggleActive Row");
                getStudyRunStatus(setActivityStatus);    
            },2000);
        }

    },[monitorExecution,isExecuting]);

    return (
        <>
            <TableContainer>
                <Table aria-label="Data Source Table">
                    <TableHead>
                        <TableRow>
                            {columns.map(column => (
                                <TableCell sx={{ fontWeight: 700, ...column?.sx }} align={column.align} key={column.label}>
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {rows.map(row => (
                            <ActivityDataSourceRow
                                key={row.id}
                                row={row}
                                onActiveChange={toggleActive}
                                onMonitorStatus={monitorActive}
                            />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}

const columns = [
    {
        align: 'center',
        label: 'Status',
    },
    {
        align: 'left',
        label: 'Filename',
    },
    {
        align: 'left',
        label: 'Activity',
    },
    {
        align: 'left',
        label: 'Date Range',
    },
    {
        align: 'left',
        label: 'Value Field',
    },
    {
        align: 'left',
        label: 'Mine Area Field',
    },
    {
        align: 'center',
        label: ''
    }
]

const defaultRows = [
    {
        id: 1,
        runStatus: false,
        filename: '',
        activity: 'Development',
        scenarioName: 'Jumbo',
        valueField: 'DrivingAdvanceReconciled',
        mineAreaField: 'UGLocationName',
        dateRange: 'NA'
    },
    {
        id: 2,
        runStatus: false,
        filename: '',
        activity: 'Trucking',
        scenarioName: 'Trucking',
        valueField: 'TKM',
        mineAreaField: 'FromMineAreaName',
        dateRange: 'NA'
    },
    {
        id: 3,
        runStatus: false,
        filename: '',
        activity: 'Bogging',
        scenarioName: 'Bogging',
        valueField: 'TonnesReconciled',
        mineAreaField: 'MineAreaName',
        dateRange: 'NA'
    },
    {
        id: 4,
        runStatus: false,
        filename: '',
        activity: 'Drilling',
        scenarioName: 'Long Hole Drilling',
        valueField: 'LongHoleMetres',
        mineAreaField: 'MineAreaName',
        dateRange: 'NA'
    }
]